.itemMainSlider {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	overflow: hidden;
	position: relative;
	@media (width >= 1200px) {
		width: 600px;
		height: 100%;
	}
	@media (width <= 1199px) {
		justify-content: center;
	}

	.itemPageSLiderNav {
		width: 72px;
		@media (width <= 1199px) {
			position: absolute;
			top: 0;
			left: 0;
		}
		@media (width <= 639px) {
			display: none;
		}
		.slick-list {
			overflow: visible;
		}
		.slick-slide {
			border: 1px solid #B1B5C2;
			transition: border-color .3s;
			position: relative;
			cursor: pointer;
			&::before {
				position: absolute;
				display: block;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 5px;
				border-color: transparent transparent transparent #FF0009;
				top: calc(50% - 5px);
				right: -5px;
				transition: opacity .3s;
				opacity: 0;
			}
			&::after {
				position: absolute;
				display: block;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 4px 0 4px 4px;
				border-color: transparent transparent transparent white;
				top: calc(50% - 4px);
				right: -4px;
				transition: opacity .3s;
				opacity: 0;
			}
			&.slick-current {
				border-color: #FF0009;
				&::before,
				&::after {
					opacity: 1;
				}
			}
		}
		.slick-slide,
		.slick-slide div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.slick-slide:not(:last-child){
			margin-bottom: 15px;
		}
		.video {
			position: relative;
			&::before {
				position: absolute;
				display: block;
				content: "";
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34"><circle cx="17" cy="17" r="17" fill="#ff0009"/><path fill="#fff" d="M10.46 8l15.59 9-15.59 9z"/></svg>');
				background-size: 34px 34px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
	
	.itemPageSLider {
		width: 500px;
		@media (width <= 767px) {
			width: 400px;
		}
		@media (width <= 479px) {
			max-width: 100%;
		}

		.slick-track {
			display: flex;
			align-items: center;
		}
	}

	.slick-slide img,
	.slick-slide iframe {
		width: 100%;
	}

	.slick-arrow {
		width: 35px;
		height: 65px;
		opacity: 0.7;
		transition: opacity .3s;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		&:hover {
			opacity: 1;
		}
		&::before {
			display: none;
		}
		&.slick-next {
			right: -45px;
			background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="35" height="65" viewBox="0 0 35 65"><path fill="#b1b5c2" d="M34.46 31.23L3.25.59A1.91 1.91 0 0 0 .6.49a1.8 1.8 0 0 0-.1 2.59l.1.11 29.88 29.34L.6 61.86a1.82 1.82 0 0 0 0 2.6 1.89 1.89 0 0 0 1.32.54 1.87 1.87 0 0 0 1.32-.54l31.21-30.64a1.81 1.81 0 0 0 0-2.59z"/></svg>');
		}
		&.slick-prev {
			left: -45px;
			background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="35" height="65" viewBox="0 0 35 65"><path fill="#b1b5c2" d="M.54 31.23a1.81 1.81 0 0 0 0 2.59l31.22 30.64a1.87 1.87 0 0 0 1.32.54 1.89 1.89 0 0 0 1.31-.54 1.82 1.82 0 0 0 0-2.6L4.52 32.53 34.4 3.19l.1-.11a1.8 1.8 0 0 0-.1-2.59 1.91 1.91 0 0 0-2.65.1L.54 31.23z"/></svg>');
		}
	}
	.slick-dots li button {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		border: 1px solid #B1B5C2;
		background-color: white;
		transition: background-color .3s;
		&::before {
			display: none;
		}
	}
	.slick-dots .slick-active button {
		background-color: #B1B5C2;
	}
}