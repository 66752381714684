.categoryCard {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 15px;
	border-radius: 3px;
	background-color: white;
	@media (width >= 768px) {
		padding: 25px;
	}

	&__heading {
		margin-top: 0;
		margin-bottom: 15px;
		a {
			color: var(--black);
			font-size: 18px;
			font-weight: 700;
			text-decoration: none;
			border-bottom: 1px solid transparent;
			transition: border-bottom-color .3s;
			&:hover {
				border-bottom-color: currentColor;
			}
		}
	}

	&__grid {
		display: flex;
		width: 100%;
		align-items: flex-start;
	}

	&__img {
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		
		img {
			max-width: 100%;
			height: auto;
		}
	}

	&__list {
		margin-left: 15px;
		
		a {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			line-height: 2;
			text-decoration: none;
			border-bottom: 1px solid transparent;
			transition: border-bottom-color .3s;
			&:hover {
				border-bottom-color: currentColor;
			}
		}
	}
}