.mMenuCustomers {
	padding: 20px 15px;
	display: flex;
	align-items: center;
	&__img {
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__link {
		text-decoration: none;
		color: var(--black);
		font-size: 14px;
		transition: color .3s;
		margin-left: 15px;
		&:hover {
			color: var(--red);
		}
	}
}