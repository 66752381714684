.postTestimonial {
	display: none;
	.post-active & {
		width: 100%;
		max-width: 1080px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
		display: flex;
		padding: 30px 10px;
		background-color: #f3f3f3;
	}
	&__form {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 25px 60px;
		@media (width >= 768px) {
			padding: 40px 60px;
		}
	}
	&__title {
		color: var(--black);
		font-size: 21px;
		font-weight: 700;
		line-height: 1;
		grid-column: 1 / -1;
	}
	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		grid-column: 1 / -1;
		&:nth-of-type(1),
		&:nth-of-type(2) {
			@media (width >= 768px) {
				grid-column: span 1;
			}
		}
		> span {
			@media (width >= 768px) {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				span {
					margin-bottom: 0;
				}
			}
		}
		span {
			color: var(--black);
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 10px;
			&:nth-of-type(2) {
				font-weight: 400;
			}
		}
		input[type="text"],
		input[type="email"],
		textarea {
			border-radius: 3px;
			border: 1px solid #e8e8e9;
			background-color: white;
			padding: 5px 15px;
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			width: 100%;
		}
		input[type="text"],
		input[type="email"] {
			height: 46px;
		}
		textarea {
			resize: none;
			min-height: 110px;
		}
	}
	&__submit {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 320px;
		height: 60px;
		border-radius: 3px;
		border: 1px solid #ff0009;
		background-color: #ff0009;
		color: white;
		font-family: Helvetica;
		font-size: 21px;
		font-weight: 700;
		line-height: 24px;
		transition: background-color .3s, color .3s;
		appearance: none;
		outline: none;
		box-shadow: none;
		cursor: pointer;
		margin-left: auto;
		margin-right: auto;
		&:hover {
			background-color: white;
			color: #ff0009;
		}
	}
}