.catalogMain {
	width: 100%;
	@media (width >= 1200px) {
		display: grid;
		grid-template-columns: 346px 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas: 
		"catSelected catContent" 
		"catFilter catContent";
		grid-column-gap: 2px;
		align-items: start;
		position: relative;
	}
	@media (width >= 1440px) {
		grid-column-gap: 20px;
	}
}