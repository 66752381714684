.testimonial {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 80px;
	grid-template-areas:
	"tName tAction"
	"tDate tDate"
	"tMessage tMessage";
	grid-gap: 20px;
	background-color: #f7f7eb;
	padding: 30px 10px;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 10px;
	&--respond {
		position: relative;
		padding-left: 75px;
		&::before {
			position: absolute;
			display: block;
			content: "";
			width: 38px;
			height: 30px;
			background-image: url(../img/testimonial-respond.svg);
			top: 30px;
			left: 10px;
		}
	}
	@media (width >= 768px) {
		padding-left: 20px;
		padding-right: 20px;
		grid-template-columns: auto auto 1fr;
		grid-template-areas:
		"tName tRate tDate"
		"tMessage tMessage tMessage"
		"tAction tAction tAction";
		grid-column-gap: 30px;
		align-items: start;
		margin-bottom: 20px;
		&--respond {
			padding-left: 125px;
			padding-top: 40px;
		}
	}
	@media (width >= 992px) {
		grid-template-columns: 100px 1fr 100px;
		grid-template-rows: 22px auto auto;
		grid-template-areas: 
		"tName tMessage tDate"
		"tRate tMessage ."
		". tAction .";
		grid-column-gap: 50px;
		max-width: 955px;
		padding-left: 30px;
		padding-right: 30px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		left: 20px;
		border-radius: 3px;
		&--respond {
			max-width: 785px;
			left: 105px;
			&::before {
				left: -90px;
			}
		}
		&::after {
			position: absolute;
			display: block;
			content: "";
			width: 0;
			height: 0;
			border-color: #f7f7eb #f7f7eb transparent transparent ;
			top: 22px;
			left: -38px;
			border: 20px solid;
		}
	}
	p {
		margin-top: 0;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	b {
		margin-top: 0;
		margin-bottom: 10px;
		font-weight: 700;
	}
	&__name {
		grid-area: tName;
		display: flex;
		align-items: center;
		color: var(--black);
		font-size: 14px;
		font-weight: 700;
		line-height: 22px;
		margin-top: 0;
		margin-bottom: 0;
	}
	&__rate {
		grid-area: tRate;
		display: none;
		margin-top: 0;
		margin-bottom: 0;
		align-items: center;
		@media (width >= 768px) {
			display: flex;
		}
		i {
			display: flex;
			background-image: url(../img/fave.svg);
			background-repeat: no-repeat;
			width: 17px;
			height: 20px;
			background-size: 100%;
			margin-right: 2px;
		}
	}
	&__action {
		grid-area: tAction;
		justify-self: end;
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		@media (width >= 768px) {
			justify-content: flex-start;
		}
		button {
			width: 40px;
			height: 40px;
			appearance: none;
			background-color: transparent;
			background-image: none;
			padding: 0;
			border: none;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #a7a7a7;
			transition: color .3s;
			@media (width >= 768px) {
				width: auto;
				margin-right: 15px;
			}
			&:hover {
				color: var(--green);
			}
		}
		span {
			display: none;
			@media (width >= 768px) {
				display: block;
				font-size: 12px;
				font-weight: 700;
				line-height: 22px;
				margin-left: 7px;
			}
		}
	}
	&__date {
		grid-area: tDate;
		color: var(--black);
		font-size: 12px;
		font-weight: 400;
		line-height: 22px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 0;
		margin-bottom: 0;
		@media (width >= 768px) {
			text-align: right;
		}
	}
	&__message {
		grid-area: tMessage;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		color: var(--black);
	}
}