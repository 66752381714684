.textContainer {
	background-color: white;
	padding: 40px;
	@media (width <= 1199px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	h2 {
		color: var(--black);
		font-size: 21px;
		font-weight: 700;
		letter-spacing: 0.2px;
		margin-top: 0;
		margin-bottom: 30px;
	}
	p {
		color: var(--black);
		font-size: 16px;
		font-weight: 400;
		line-height: 1.4;
		margin-top: 0;
		margin-bottom: 0;
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	a:not([class]) {
		color: currentColor;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		transition: border-bottom-color .3s;
		&:hover {
			border-bottom-color: currentColor;
		}
	}
}