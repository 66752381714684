.itemCard {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: box-shadow .3s;
	&:hover {
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
		z-index: 2;
	}
	.profitableOffers &:nth-last-child(1),
	.profitableOffers &:nth-last-child(2) {
		@media (width >= 768px) {
			display: none;
		}
		@media (width >= 1200px) {
			display: flex;
		}
	}
	&__top {
		position: absolute;
		top: 15px;
		left: 0;
		width: 100%;
		display: flex;
		opacity: 0;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
		transition: opacity .3s;
		@media (width <= 767px) {
			display: none;
		}
	}
	&:hover &__top {
		opacity: 1;
	}
	&__id {
		background-color: white;
		color: var(--grey);
		font-size: 14px;
		padding: 5px;
		border-radius: 3px;
		margin-left: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__addToFavourite {
		background-color: white;
		padding: 5px;
		border-radius: 3px;
		margin-right: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		transition: background-color .3s;
		path {
			fill: var(--red);
			transition: fill .3s;
		}
		&:hover {background-color: var(--red);}
		&:hover path {fill: white;}
	}
	&__addToFavouriteText {
		display: none;
	}
	&__img {
		display: flex;
		width: 100%;
		height: auto;
		border-radius: 3px 3px 0 0;
		overflow: hidden;
		background-color: #F3F3F3;
		img, picture {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	&__content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		background-color: white;
		width: 100%;
		@media (width >= 768px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	&__labelHolder {
		position: absolute;
		top: 10px;
		right: 0;
		@media (width >= 768px) {
			position: static;
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&__label {
		color: white;
		font-size: 10px;
		font-weight: 700;	
		display: flex;
		padding: 5px 10px;
		text-transform: uppercase;
		background-color: var(--itemCardLabelBg);
		&--sales {--itemCardLabelBg: #ff0009;}
		&--discount {--itemCardLabelBg: #e8b734;}
		&--new {--itemCardLabelBg: #75cb40;}
		&--top {--itemCardLabelBg: #8921b7;}
	}
	&__testimonialsHolder {
		display: flex;
		width: 100%;
		min-height: 30px;
		justify-content: space-between;
		align-items: center;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	&__testimonialsQty {
		color: var(--grey);
		font-size: 13px;
		font-weight: 400;
	}
	&__testimonialsRate {
		display: flex;
		align-items: center;
		justify-content: center;
		i {
			display: flex;
			width: 17px;
			height: 20px;
			background-image: url(../img/fave.svg);
			background-repeat: no-repeat;
			background-size: 100%;
		}
		b {
			color: var(--black);
			font-size: 15px;
			font-weight: 700;
			margin-left: 5px;
			line-height: 20px;
		}
	}
	&__title {
		color: var(--black);
		font-size: 16px;
	}
	&__prices {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
	}
	&__price {
		color: var(--black);
		font-size: 16px;
		font-weight: 500;
		margin-right: 5px;
		@media (width >= 768px) {
			font-size: 19px;
		}
		&--sale {
			color: #ff0009;
		}
		&--old {
			color: var(--grey);
			font-size: 13px;
			text-decoration: line-through;
			@media (width >= 768px) {
				font-size: 16px;
			}
		}
	}
	.itemCardBonus {
		margin-left: auto;
	}
	&__buyBtnHolder {
		--buyBtnIndent: 10px;
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + 20px);
		justify-content: center;
		margin-left: -10px;
		margin-right: -10px;
		margin-top: auto;
		padding-left: var(--buyBtnIndent);
		padding-right: var(--buyBtnIndent);
		padding-bottom: 10px;
		align-items: center;
		@media (width >= 768px) {
			--buyBtnIndent: 20px;
			width: 100%;
			margin-left: 0;
			margin-right: 0;
			display: none;
			position: absolute;
			top: calc(100% - 1px);
			z-index: 1;
			left: 0;
			background-color: white;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
			border-radius: 0 0 3px 3px;
			overflow: hidden;
		}
	}
	&:hover &__buyBtnHolder {
		@media (width >= 768px) {
			display: flex;
		}
	}
	&__buyBtn {
		width: 100%;
		height: 40px;
		background-color: #00A705;
		border: 1px solid #00A705;
		color: white;
		font-size: 16px;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		border-radius: 3px;
		text-decoration: none;
		transition: background-color .3s;
		@media (width >= 768px) {
			height: 55px;
		}
		&:hover {
			background-color: #009004;
			border-color: #009004;
			color: white;
		}
	}

	&__moreInfo {
		flex-basis: 50%;
		flex-grow: 1;
		height: 40px;
		background-color: #E8E8E9;
		border: 1px solid #E8E8E9;
		color: #333333;
		font-size: 16px;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		border-radius: 3px;
		text-decoration: none;
		transition: background-color .3s;
		@media (width >= 768px) {
			height: 55px;
		}
		&:hover {
			background-color: white;
		}
	}
	&__naMessage {
		color: var(--black);
		font-size: 13px;
		font-weight: 700;
		position: relative;
		padding-left: 20px;
		margin-top: 10px;
		&::before {
			position: absolute;
			width: 10px;
			height: 2px;
			background-color: #ff1b32;
			display: block;
			content: "";
			left: 5px;
			top: 50%;
		}
	}
}