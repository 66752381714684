.headerPerson {
	grid-area: hPerson;
	display: flex;
	align-items: center;
	padding-right: 10px;
	padding-top: 10px;
	justify-content: flex-end;
	@media (width >= 768px) {
		padding-right: 32px;
		padding-top: 12px;
		flex-basis: auto;
	}
	@media (width >= 1200px) {
		padding-right: 0;
	}
	&__item {
		width: 20px;
		height: 20px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		position: relative;
		text-decoration: none;
		color: var(--dark);
		@media (width >= 768px) {
			width: 35px;
			height: 35px;
			&:not(:first-child) {margin-left: 20px;}
		}
		@media (width >= 1200px) {
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			width: auto;
			height: auto;
		}
		&--account,&--favourite {
			display: none;
			@media (width >= 768px) {display: flex;}
		}
	}
	&__itemIcon, &__itemIcon svg {
		width: 100%;
		height: 100%;
		@media (width >= 1200px) {
			height: 22px;
			width: auto;
		}
		path {
			fill: #999999;
			transition: fill .3s;
			@media (width >= 1200px) {
				fill: var(--black);
			}
			.headerPerson__item:hover & {fill: var(--black);}
		}
	}
	&__itemName {
		display: none;
		color: var(--black);
		@media (width >= 1200px) {
			display: block;
			margin-left: 5px;
			font-size: 16px;
		}
	}
	&__itemCounter {
		position: absolute;
		top: 0;
		right: 0;
		width: 20px;
		height: 20px;
		transform: translate(50%,-50%);
		border-radius: 50%;
		background-color: var(--red);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 11px;
		font-weight: bold;
		color: white;
		@media (width >= 768px) {
			width: 24px;
			height: 24px;
		}
		@media (width >= 1200px) {
			width: auto;
			height: auto;
			flex-direction: row;
			position: relative;
			transform: none;
			background-color: transparent;
			color: var(--red);
			font-size: 16px;
			font-weight: 400;
			padding-bottom: 1px;
			padding-left: 5px;
			padding-right: 5px;
			&::before {content: "(";}
			&::after {content: ")";}
		}
	}
	&__item.js-active &__itemIcon svg path {fill: var(--red);}
}

.dropdownOrderBtn {
	width: 100%;
	height: 40px;
	background-color: #00A705;
	border: 1px solid #00A705;
	color: white;
	font-size: 16px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	border-radius: 3px;
	text-decoration: none;
	transition: background-color .3s;
	cursor: pointer;
	@media (width >= 768px) {
		height: 55px;
	}
	&:hover {
		background-color: #009004;
		border-color: #009004;
		color: white;
	}
}