.footerTels {
	display: none;
	position: relative;
	@media (width >= 768px) {
		display: flex;
		align-items: flex-start;
		min-width: 180px;
		width: auto;
		height: auto;
		border-radius: 0;
		background-color: transparent;
		justify-content: flex-start;
		margin-left: 0;
		margin-right: 20px;
		margin-bottom: 10px;
	}
	.js-dropdownFirstTrigger {
		position: absolute;
		width: auto;
		right: 0;
		height: 100%;
	}
	&__current {
		display: block;
		font-size: 24px;
		font-weight: 500;
		color: var(--black);
		position: relative;
		z-index: 2;
	}
	&__dropdown {
		top: -30px;
		left: -5px;
		background-color: white;
		border-radius: 3px;
		box-shadow: 0 0 6px rgba(#000, .16);
		width: calc(100% + 15px);
		padding: 15px;
		ul {
			display: flex;
			flex-direction: column;
			width: auto;
		}
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;			
			&:not(:last-child) {margin-bottom: 10px;}
		}
		li > span, li > a {
			margin-left: auto;
			flex-basis: calc(100% - 30px);
		}
		li > a {
			text-decoration: none;
			color: var(--black);
			font-size: 16px;
			font-weight: bold;
			transition: color .3s;
			&:hover {color: var(--blue);}
		}
		li > span {
			display: flex;
			flex-direction: column;
			color: #6D6D6D;
			font-size: 11px;
		}
	}
}