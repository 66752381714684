.mMenuCity {
	display: flex;
	flex-direction: column;
	width: 100%;
	&__current {
		padding: 20px;
		display: flex;
		border-bottom: 1px solid #E0E0E0;
		text-decoration: none;
	}
	&__text {
		color: var(--black);
		font-size: 14px;
		font-weight: bold;
		margin-right: 10px;
	}
	&__toggle {
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url(../img/arrowDown.svg);
		background-size: 9px 5px;
		background-repeat: no-repeat;
		background-position: center;
		width: 15px;
		height: 15px;
		transition: transform .3s;
		.active & {transform: rotate(180deg);}
	}
	&__listTitle {
		display: block;
		padding: 15px 20px;
		color: var(--black);
		font-size: 13px;
		font-weight: bold;
	}
	&__list {
		display: flex;
		flex-direction: column;
		width: 100%;
		li {
			display: flex;
			width: 100%;
		}
		a {
			width: 100%;
			padding: 10px 20px;
			text-decoration: none;
			color: var(--black);
			font-size: 13px;
			transition: background-color .3s;
			&:hover {background-color: #F3F3F3;}
		}
	}
	&__form {
		display: flex;
		width: 100%;
		padding: 20px;
	}
	&__input {
		width: 100%;
		height: 40px;
		padding: 10px;
		border-radius: 3px;
		border: 1px solid #E8E8E9;
		appearance: none;
		color: var(--black);
		background-color: transparent;
		color: var(--black);
		font-size: 13px;
		&:focus {outline-color: #ED8A19;}
	}
}