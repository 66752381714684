.account-content {
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
	@media (width >= 1200px) {
		display: grid;
		position: relative;
		grid-template-columns: 1fr 310px;
		align-items: start;
		margin-top: 20px;
		margin-bottom: 70px;
		grid-column-gap: 20px;
	}
}