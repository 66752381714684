.blog-grid-wrapper {
	display: grid;
	grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
	grid-gap: 22px;
	@media (width <= 1799px) {
		grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
		grid-gap: 20px;
	}
	@media (width <= 1199px) {
		grid-template-columns: repeat( auto-fit, minmax(270px, 1fr) );
	}
	@media (width <= 991px) {
		grid-template-columns: repeat( 2, minmax(270px, 315px) );
		justify-content: center;
	}
	@media (width <= 639px) {
		grid-template-columns: repeat( 1, minmax(270px, 315px) );
	}
	.blogTeaserCard {
		width: 100%;
	}
}