.inputLine {
	margin-bottom: 20px;
	input {
		position: absolute;
		opacity: 0;
		z-index: -1;
		cursor: pointer;
	}
	label {
		color: var(--black);
		font-size: 16px;
		font-weight: 400;
		padding-left: 30px;
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;
		&::before {
			position: absolute;
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			border: 1px solid #b1b5c2;
			background-color: white;
			top: 0;
			left: 0;
		}
		&::selection {
			background-color: transparent;
		}
	}
	input:checked + label::after {
		position: absolute;
		display: flex;
	}
	&--checkbox {
		label::before {
			border-radius: 3px;
		}
		input:checked + label::before {
			background-color: #b1b5c2;
		}
		input:checked + label::after {
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			top: 0;
			left: 0;
			color: white;
			content: "✔";
		}
	}
	&--radio {
		label::before {
			border-radius: 50%;
		}
		input:checked + label::after {
			content: "";
			top: 5px;
			left: 5px;
			width: 10px;
			height: 10px;
			background-color: var(--blue);
			border-radius: 50%;
		}
	}
}

.formLine {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	input,select {
		width: 100%;
		height: 46px;
		border-radius: 3px;
		border: 1px solid #b1b5c2;
		padding-left: 15px;
		padding-right: 15px;
		color: var(--black);
		font-size: 16px;
		font-weight: 400;
		box-shadow: none;
	}
	textarea {
		border-radius: 3px;
		border: 1px solid #b1b5c2;
		width: 100%;
		min-height: 100px;
		resize: vertical;
		padding: 15px;
	}
	.accentLabel {
		color: #ff1b32;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 10px;
	}
	.helpLabel {
		margin-top: 10px;
		color: var(--grey);
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;
		a {
			margin-left: 5px;
			text-decoration: none;
			transition: color .3s;
			&:hover {color: var(--red);}
		}
	}
}