.social {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	&__heading {
		font-size: 16px;
		font-weight: bold;
		color: var(--black);
		margin-bottom: 20px;
	}
	&__list {
		display: flex;
		align-items: center;
	}
	&__item:not(:last-child) {
		margin-right: 10px;
	}
	&__link {
		text-decoration: none;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid #DEDEDE;
		background-color: transparent;
		transition: background-color .3s;
		&:hover {
			background-color: #DEDEDE;
		}
	}
}