.specifications {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	outline: none!important;
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		background-color: #FFF;
		padding: 30px 10px;
		@media (width >= 768px) {
			padding: 40px 20px;
		}
		@media (width >= 1200px) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}
	&__line {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border-bottom: 1px dotted #DBDBDB;
		padding-top: 5px;
		padding-bottom: 5px;
		@media (width >= 480px) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding-top: 10px;
			padding-bottom: 10px;
		}
		@media (width >= 640px) {
			border-bottom: none;
		}
		@media (width >= 1200px) {
			flex-basis: 49%;
			max-width: 49%;
		}
		span,a {
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
		}
		span:last-child,
		a:last-child {
			font-weight: 700;
		}
		a {
			color: #2b6bdd;
			text-decoration: none;
		}
		span:first-child {
			@media (width >= 640px) {
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: baseline;
				flex-grow: 1;
				&::after {
					display: block;
					content: "";
					border-bottom: 1px dotted #DBDBDB;
					flex-grow: 1;
					width: 100px;
					margin-left: 10px;
					margin-right: 10px;
				}
			}
		}
	}
}