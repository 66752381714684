.itemFixedHeader {
	display: none;
	@media (width >= 992px) {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		background-color: white;
		width: 100%;
		position: fixed;
		z-index: 31;
		top: 0;
		left: 0;
		width: 100%;
		transform: translateY(-100%);
		animation: fixedHeader .3s forwards;
		box-shadow: 0 3px 6px rgba(#000, .16);
		display: none;
		.header-is-fixed & {
			display: block;
		}
		&__wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media (width <= 1199px) {
				align-items: baseline;
			}
		}
		&__mainText {
			display: flex;
			align-items: center;
			max-width: 450px;
			@media (width <= 1199px) {
				max-width: 290px;
			}
			img {
				width: 50px;
				height: 50px;
				object-fit: cover;
				object-position: center;
				@media (width <= 1199px) {
					display: none;
				}
			}
			.text {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding-left: 20px;
				max-width: calc(100% - 70px);
				@media (width <= 1199px) {
					max-width: 100%;
				}
			}
		}
		&__name {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			line-height: 22px;
			max-width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		&__links {
			display: flex;
			align-items: center;
			@media (width <= 1199px) {
				display: none;
			}
			a {
				color: #2b6bdd;
				font-size: 12px;
				font-weight: 400;
				line-height: 21px;
				text-decoration: none;
				transition: opacity .3s;
				&:hover {
					opacity: 0.7;
				}
			}
			span {
				color: var(--grey);
				font-family: Helvetica;
				font-size: 12px;
				font-weight: 400;
				line-height: 21px;
				margin-left: 10px;
				a {
					padding-right: 5px;
				}
			}
		} 
		&__price {
			display: flex;
			align-items: center;
			span {
				margin-right: 10px;
			}
			.current {
				color: #ff0009;
				font-size: 19px;
				font-weight: 500;
				line-height: 21px;
			}
			.regular {
				color: var(--black);
				font-size: 19px;
				font-weight: 500;
				line-height: 21px;
			}
			.old {
				color: var(--grey);
				font-size: 16px;
				font-weight: 500;
				line-height: 21px;
				text-decoration: line-through;
			}
		}
		&__variants {
			width: 200px;
		}
		&__buy {
			width: 110px;
			height: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 3px;
			background-color: #00A705;
			border: 1px solid #00A705;
			color: white;
			font-size: 16px;
			font-weight: 700;
			line-height: 21px;
			text-decoration: none;
			transition: background-color .3s;
			@media (width <= 1199px) {
				transform: translateY(-5px);
			}
			&:hover {
				background-color: #009004;
				border-color: #009004;
				color: white;
			}
		}
		&__cart {
			width: 153px;
			height: 100%;
			min-height: 70px;
			background-color: #f3f3f3;
			display: flex;
			align-items: center;
			justify-content: center;
			.text {
				margin-left: 10px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			.qty {
				color: var(--black);
				font-size: 16px;
				font-weight: 400;
				span {
					margin-left: 5px;
					color: #ff0009;
				}
			}
			.checkout {
				color: #2b6bdd;
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				text-decoration: none;
				transition: opacity .3s;
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

}