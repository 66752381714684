.header-is-fixed {
	margin-top: var(--headerFixedIndent);
}
@keyframes fixedHeader {
	from {transform: translateY(-100%);}
	to {transform: translateY(0);}
}

.header-is-fixed .siteHeader {
	position: fixed;
	z-index: 30;
	top: 0;
	left: 0;
	width: 100%;
	transform: translateY(-100%);
	animation: fixedHeader .3s forwards;
	box-shadow: 0 3px 6px rgba(#000, .16);
	@media (width >= 768px) {
		border-top: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
	&__wrapper {
		grid-template-areas: 
		"hButton hLogo hPerson";
		@media (width >= 768px) {
			grid-template-areas: 
			"hCatalogue hBrands hSearch hSearch hPerson";
		}
		@media (width >= 1200px) {
			grid-column-gap: 15px;
			grid-template-columns: 190px 70px 1fr 0 420px;
			padding-top: 10px;
			padding-bottom: 10px;
		}
		@media (width >= 1440px) {
			grid-template-columns: 160px 190px 70px 1fr 370px;
			grid-template-areas: 
			"hLogo hCatalogue hBrands hSearch hSearch hPerson";
		}
	}
	.headerLogo {
		@media (width >= 768px) {display: none;}
		@media (width >= 1440px) {
			display: flex;
			filter: grayscale(1);
			svg {
				width: 45px;
				height: auto;
			}
			picture, img {
				width: 100px;
				height: auto;
			}
		}
		.headerLogo__link path {fill: var(--black);}
	}

	.siteHeader__headerBar--topLeft {
		@media (width >= 768px) {display: none;}
	}
	

	.headerSearch {
		display: none;
		@media (width >= 768px) {
			display: block;
			&__form {height: 50px;}
		}
		@media (width >= 1440px) {
			&__form {height: 40px;}
		}
	}

	.headerPerson {
		@media (width >= 768px) {
			&__item {height: 30px;}
			&__itemIcon svg {height: 22px;}
		}
		@media (width >= 1200px) {padding-top: 1px;}
	}

	.headerCatalogue {
		display: none;
		@media (width >= 768px) {
			display: flex;
			height: 50px;
			&__btn.burger {height: 48px;}
		}
		@media (width >= 1200px) {
			border-radius: 3px;
			justify-content: center;
			&__btn {display: none;}
			&__holder {
				margin-left: 0;
				svg {display: block;}
			}
		}
		@media (width >= 1440px) {
			height: 40px;
			&__btn.burger {height: 38px;}
		}
	}

	.headerBrands {
		@media (width >= 768px) {
			ul {height: 50px;}
		}
		@media (width >= 1200px) {
			width: 70px;
			padding-left: 0;
			padding-right: 0;
			border-radius: 3px;
			.is-visible {text-transform: uppercase;}
			&__linkPage:not(.is-visible) {display: none;}
		}
		@media (width >= 1440px) {
			ul {height: 40px;}
		}
	}

	.siteHeader__headerBar--topRight {display: none;}

}