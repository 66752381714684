.itemMainPrice {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 10px;
	padding-bottom: 10px;
	@media (width >= 768px) {
		flex-direction: row;
	}
	&__newPrice {
		display: flex;
		align-items: flex-start;
		color: var(--red);
		font-size: 32px;
		font-weight: 400;
		@media (width >= 768px) {
			font-size: 42px;
		}
		sup {
			font-size: 16px;
			@media (width >= 768px) {
				font-size: 21px;
			}
		}
	}
	&__regularPrice {
		display: flex;
		align-items: flex-start;
		color: #333333;
		font-size: 32px;
		font-weight: 400;
		@media (width >= 768px) {
			font-size: 42px;
		}
		sup {
			font-size: 16px;
			@media (width >= 768px) {
				font-size: 21px;
			}
		}
	}
	&__oldPrice {
		color: var(--grey);
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.16px;
		text-decoration: line-through;
		@media (width >= 768px) {
			font-size: 23px;
			margin-left: 15px;
			margin-top: 3px;
		}
	}
}