.productTestimonialsCard {
	background-color: white;
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: box-shadow .3s;
	margin: 2px 1px 6px;
	&:hover {
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
		z-index: 2;
	}
	&__img {
		display: flex;
		background-color: #F3F3F3;
		width: 100%;
		height: auto;
		overflow: hidden;
		border-radius: 3px 3px 0 0;
		text-decoration: none;
		img, picture {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	&__title {
		display: flex;
		padding: 10px 20px;
		font-size: 12px;
		color: var(--black);
		text-align: center;
		&::selection {background-color: transparent;}
	}
	&__content {
		padding: 10px 20px;
		display: flex;
		width: 100%;
		flex-direction: column;
		font-size: 12px;
		color: #A7A7A7;
		& *::selection {background-color: transparent;}
	}
	&__footer {
		padding: 10px 20px;
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		align-items: center;
	}
	&__name {
		font-size: 12px;
		color: var(--black);
		padding-right: 10px;
		margin-right: auto;
		margin-bottom: 5px;
		&::selection {background-color: transparent;}
	}
}