.catDirection {
	width: 100%;
	height: 100%;
	position: relative;
	outline: none;
	@media (width >= 992px) {
		height: auto;
		padding-top: 25px;
		padding-bottom: 25px;
	}
	@media (width >= 1440px) {
		grid-column: 2/3;
	}
	&__holder {
		width: 100%;
		height: 100%;
		background-color: var(--catDirectionBg);
		display: flex;
		justify-content: space-between;
		@media (width <= 991px) {
			background-color: white;
		}
	}
	&__sidebar {
		flex-basis: 240px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@media (width <= 991px) {
			flex-basis: 100%;
		}
	}
	&__title {
		width: 100%;
		height: 100%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		@media (width >= 992px) {
			flex-direction: row;
			height: auto;
			&:hover {box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);}
		}
		@media (width <= 991px) {
			transition: opacity .3s;
			&:hover {opacity: 0.7;}
		}
	}
	&__icon {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		@media (width >= 992px) {
			margin-right: 20px;
		}		
	}
	&__name {
		font-size: 18px;
		font-weight: 700;
		color: var(--catDirectionColor);
		@media (width <= 991px) {
			text-align: center;
			margin-top: 10px;
		}
	}
	&__list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px;
		@media (width <= 991px) {display: none;}
		li {
			margin-top: 7px;
			margin-bottom: 7px;
		}
		a {
			text-decoration: none;
			font-size: 16px;
			color: var(--black);
			transition: color .3s;
			&:hover {
				color: #ff0009;
			}
		}
	}
	&__all {
		width: 156px;
		height: 40px;
		border: 1px solid #e8e8e9;
		background-color: white;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
		text-decoration: none;
		transition: opacity .3s;
		@media (width <= 991px) {display: none;}
		&:hover {
			opacity: 0.7;
		}
		span {
			font-size: 14px;
			color: var(--catDirectionColor);
			margin-right: 10px;
		}
		svg {
			transform: rotate(-90deg);
		}

	}
	&__cards {
		flex-basis: calc(100% - 255px);
		display: grid;
		grid-gap: 2px;
		grid-template-columns: repeat(3, calc(33.33% - 1px));
		padding: 30px;
		@media (width <= 991px) {display: none;}
	}
}

.catDirection--2 {
	--catDirectionBg: #f4e9ea;
	--catDirectionColor: #ff9096;
}

.catDirection--3 {
	--catDirectionBg: #f0e9f0;
	--catDirectionColor: #ae00b1;
}

.catDirection--4 {
	--catDirectionBg: #e4eede;
	--catDirectionColor: #74cd46;
}

.catDirection--5 {
	--catDirectionBg: #f3efe5;
	--catDirectionColor: #f7b700;
}

.catDirection--6 {
	--catDirectionBg: #e0eef4;
	--catDirectionColor: #00b6fe;
}

.catDirection--7 {
	--catDirectionBg: #e0f0ec;
	--catDirectionColor: #00c995;
}

.catDirection--8 {
	--catDirectionBg: #ffffff;
	--catDirectionColor: #fc0059;
}

.catDirection--9 {
	--catDirectionBg: #e7edf1;
	--catDirectionColor: #0077cb;
}

.catDirection--10 {
	--catDirectionBg: white;
	--catDirectionColor: #2b3c4d;
}

.catDirection--10 {
	display: none;
	outline: none;
	margin-top: 25px;
	@media (width >= 1200px) {
		display: block;
	}
	.catDirection__holder {
		min-height: 590px; 
	}
	.catDirection__sidebar {
		background-image: url(../img/well-bag-1.jpg);
		background-size: cover;
	}
	.catDirection__all {
		margin-top: auto;
		margin-bottom: 45px;
	}
	.catDirection__cards {
		padding: 20px;
		display: grid;
		grid-gap: 1px;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(4, 1fr);
	}

	.catDirection__cards a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		transition: opacity .3s;
		&:hover {opacity: 0.7;}
	}

	.catDirection__cards img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
} 