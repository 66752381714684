.accountMain--account-details {
	padding: 25px 20px;
	background-color: white;
	@media (width >= 1200px) {
		padding: 30px 40px;
	}
	.account-details__heading {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		@media (width >= 1200px) {
			flex-direction: row;
			justify-content: space-between;
		}
		h2 {
			color: var(--black);
			font-size: 16px;
			font-weight: 700;
			margin-top: 0;
			margin-bottom: 10px;
			@media (width >= 1200px) {
				font-size: 24px;
				margin-bottom: 0;
			}
		}
		span {
			color: #979a9a;
			font-size: 12px;
			font-weight: 400;
			@media (width >= 1200px) {
				font-size: 14px;
			}
		}
	}
	.account-details__form {
		width: 100%;
		max-width: 680px;
		display: flex;
		flex-direction: column;
		margin: 25px auto 40px;
		@media (width >= 1200px) {
			margin-left: 0;
		}
	}
	.form-lines {
		display: flex;
		align-items: flex-start;
		@media (width >= 1200px) {
			justify-content: space-between;
		}
		.form-line:first-of-type {
			margin-right: 20px;
		}
		.form-line:not(:first-of-type) label {
			min-width: 140px;
		}
	}
	.form-line {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 20px;
		@media (width >= 1200px) {
			flex-direction: row;
			align-items: center;
			width: auto;
		}
	}
	label {
		color: var(--black);
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
		@media (width >= 1200px) {
			min-width: 200px;
		}
	}
	.required label::after {
		content: "*";
		font-size: 14px;
		color: #ff1b32;
		margin-left: 5px;
	}
	input, select {
		width: 100%;
		height: 40px;
		border-radius: 3px;
		border: 1px solid #b1b5c2;
		background-color: transparent;
		box-shadow: none;
		padding: 5px 12px;
		color: var(--black);
		font-size: 14px;
		font-weight: 700;
		box-shadow: none;
		appearance: none;
	}
	.form-group {
		width: calc(100% + 4px);
		display: flex;
		margin-left: -2px;
		margin-right: -2px;
	}
	.form-item {
		flex-basis: 33%;
		flex-grow: 1;
		margin-left: 2px;
		margin-right: 2px;
		appearance: none;
		position: relative;
		&::after {
			position: absolute;
			content: "";
			display: block;
			width: 38px;
			height: 38px;
			top: 1px;
			right: 1px;
			background-image: url(../img/arrowDown.svg);
			background-size: 9px 6px;
			background-position: center;
			background-repeat: no-repeat;
			background-color: white;
			pointer-events: none;
			border-radius: 3px;
		}
	}
	.form-buttons {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 1200px) {
			flex-direction: row;
			justify-content: center;
		}
		button {
			width: 100%;
			max-width: 280px;
			margin-top: 10px;
			appearance: none;
			box-shadow: none;
			background-image: none;
			box-shadow: none;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px 30px;
			height: 40px;
			border-radius: 30px;
			transition: color .3s, background-color .3s;
			font-size: 14px;
			font-weight: 700;
			background-color: white;
			cursor: pointer;
			@media (width >= 1200px) {
				width: auto;
				margin-left: 10px;
				margin-right: 10px;
			}
		}
		.submit {
			border: 1px solid #ff1b32;
			color: #ff1b32;
			&:hover {
				color: white;
				background-color: #ff1b32;
			}
		}
		.cancel {
			border: 1px solid #e8e8e9;
			color: var(--black);
			&:hover {
				background-color: #e8e8e9;
			}
		}
	}
	.account-details__social {
		display: flex;
		width: 100%;
		max-width: 680px;
		margin: 25px auto 40px;
		flex-direction: column;
		align-items: flex-start;
		@media (width >= 1200px) {
			max-width: none;
			margin-left: 0;
		}
		h2 {
			color: var(--black);
			font-size: 16px;
			font-weight: 700;
			margin-top: 0;
			margin-bottom: 15px;
			@media (width >= 1200px) {
				font-size: 21px;
			}
		}
		p {
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			margin-top: 0;
			margin-bottom: 15px;
		}
		small {
			color: #979a9a;
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	.social-btn {
		margin-bottom: 15px;
		width: 100%;
		max-width: 350px;
	}
	.icon {
		position: relative;
		&::after {
			position: absolute;
			display: block;
			width: 50px;
			height: 46px;
			content: "";
			background-repeat: no-repeat;
			background-position: center;
			background-size: 20px 20px;
			z-index: 2;
			top: 0;
			left: 0;
		}
		input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&::before {
			display: block;
			position: absolute;
			content: "";
			width: 50px;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(#000, .15);
			z-index: 1;
		}
	}
	.loginFB,.loginG {
		width: 100%;
		height: 46px;
		background-color: var(--accentColor);
		border: 1px solid var(--accentColor);
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		padding-left: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: 16px;
		font-weight: 700;
		text-decoration: none;
		transition: opacity .3s;
		&:hover {
			opacity: 0.7;
			color: white;
		}
	}
	.loginFB {--accentColor: #3C539B;}
	.loginG {--accentColor: #437BF9;}

	.icon--fb::after {background-image: url(../img/icon--fb.svg);}
	.icon--gp::after {background-image: url(../img/icon--gp.svg);}
}