.contactsMap {
	h3 {
		color: var(--black);
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 10px;
		padding-top: 30px;
		@media (width <= 767px) {font-size: 16px;}
		@media (width >= 768px) {font-size: 18px;}
	}
	.mapForm {
		position: relative;
		@media (width >= 768px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
			width: 100%;
			align-items: center;
		}
		&__item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			@media (width <= 767px) {
				margin-bottom: 15px;
			}
		}
		label {
			color: var(--black);
			font-size: 15px;
			font-weight: 400;
			margin-bottom: 10px;
		}
		input, select {
			width: 100%;
			height: 38px;
			border-radius: 3px;
			border: 1px solid #b1b5c2;
			padding: 5px 12px;
			color: var(--black);
			font-size: 14px;
			font-weight: 700;
		}
		[type='submit'] {
			position: absolute;
			width: 40px;
			height: 40px;
			right: 0;
			bottom: -1px;
			font-size: 0;
			appearance: none;
			border: none;
			box-shadow: none;
			background-color: transparent;
			background-image: url(../img/icon--search.svg);
			background-size: 20px 20px;
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
			outline: none!;
			transition: opacity .3s;
			&:hover {
				opacity: 0.7;
			}
		}
	}

	.map {
		margin-top: 30px;
		height: 500px;
		width: calc(100% + 40px);
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: -40px;
		@media (width >= 1200px) {
			margin-left: -40px;
			margin-right: -40px;
			width: calc(100% + 80px);
		}
	}
}