.contactsRequest {
	padding-top: 30px;
	padding-bottom: 40px;
	position: relative;
	@media (width <= 767px) {
		text-align: center;
	}
	@media (width >= 768px) {
		padding-top: 30px;
		margin-top: 20px;
	}
	&::before {
		position: absolute;
		display: block;
		content: "";
		width: calc(100% + 40px);
		height: 10px;
		left: -20px;
		background-color: #f3f3f3;
		top: 0;
		@media (width >= 1200px) {
			left: -40px;
			width: calc(100% + 80px);
		}
	}
	&::after {
		position: absolute;
		display: block;
		content: "";
		width: calc(100% + 40px);
		height: 10px;
		left: -20px;
		background-color: #f3f3f3;
		bottom: 0;
		@media (width >= 1200px) {
			left: -40px;
			width: calc(100% + 80px);
		}
	}
	h3 {
		color: var(--black);
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 10px;
		@media (width <= 767px) {font-size: 16px;}
		@media (width >= 768px) {font-size: 18px;}
	}
	p {
		color: var(--grey);
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 15px;
	}
	.contactsForm {
		@media (width <= 767px) {
			display: block;
		}
		@media (width >= 768px) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 20px;
			align-items: end;
		}
		&__item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			margin-bottom: 15px;
			@media (width >= 768px) {grid-column: 1 / -1;}
			label {
				color: var(--black);
				font-size: 15px;
				font-weight: 400;
				margin-bottom: 5px;
			}
			sup {
				color: #ff1b32;
				font-size: 14px;
				font-weight: 700;
			}
			input, textarea {
				width: 100%;
				height: 38px;
				border-radius: 3px;
				border: 1px solid #b1b5c2;
				padding: 5px 12px;
				color: var(--black);
				font-size: 14px;
				font-weight: 700;
			}
			textarea {
				resize: vertical;
				min-height: 100px;
			}
		}
		&__item--narrow {
			@media (width >= 768px) {
				grid-column: span 1;
			}
		}
		&__item--file {
			position: relative;
			min-height: 35px;
			&::before {
				display: block;
				content: attr(data-content);
				position: absolute;
				top: 10px;
				left: 0;
				color: #2b6bdd;
				font-size: 14px;
				line-height: 18px;
				font-weight: 700;
				padding-left: 25px;
				background-image: url(../img/icon--file.svg);
				background-size: 18px 18px;
				background-repeat: no-repeat;
				background-position: top left;
				cursor: pointer;
				z-index: 1;
				transition: opacity .3s;
			}
			&:hover::before {
				opacity: .7;
			}
			label, input {
				opacity: 0;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
		[type='submit'] {
			height: 40px;
			border-radius: 30px;
			background-color: #ff0009;
			border: 1px solid #ff0009;
			color: white;
			font-size: 14px;
			font-weight: 700;
			display: flex;
			justify-content: center;
			align-items: center;
			appearance: none;
			padding: 2px 20px;
			transition: background-color .3s, color .3s;
			cursor: pointer;
			min-width: 130px;
			@media (width <= 767px) {
				width: 100%;
				max-width: 280px;
				margin-left: auto;
				margin-right: auto;
			}
			&:hover {
				color: #ff0009;
				background-color: white;
			}
		}
	}
}