.accordion-container {
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	.ac {
		border: 1px solid #e8e8e8;
		background-color: #f3f3f3;
		min-height: 60px;
		width: 100%;
		&:focus-within {
			border-color: #ffd1d5;
		}
		&:not(:last-child) {
			margin-bottom: 5px;
		}
		.ac-category-heading {
			margin-bottom: 20px;
		}
		.ac-q {
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			line-height: normal;
			margin-top: 0;
			margin-bottom: 0;
			position: relative;
			padding: 20px 40px 20px 20px;
			outline-color: transparent;
			cursor: pointer;
			&::after {
				display: block;
				position: absolute;
				content: "";
				width: 14px;
				height: 8px;
				background-size: 14px 8px;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url(../img/account/accordion-title-trigger.svg);
				transition: transform .3s;
				pointer-events: none;
				top: 25px;
				right: 20px;
			}
		}
		.ac-a {
			overflow: hidden;
			visibility: hidden;
			p {
				color: var(--black);
				font-size: 14px;
				font-weight: 400;
				line-height: normal;
				padding-left: 20px;
				padding-right: 20px;
				margin-bottom: 20px;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}
		&.active .ac-q::after {
			transform: rotateX(180deg);
		}
		&.active .ac-a {
			visibility: visible;
		}
	}
}