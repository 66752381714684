.account-item-grid {
	border-top: 2px solid #F3F3F3;
	border-bottom: 2px solid #F3F3F3;
	background-color: #F3F3F3;
	width: 100%;
	display: grid;
	grid-gap: 2px;
	grid-template-columns: repeat(2, calc(50% - 1px));
	@media (width >= 768px) {
		grid-template-columns: repeat(3, calc(33.33% - 1px));
	}
}