.catalogFilters {
	display: flex;
	z-index: 31;
	position: fixed;
	top: 0;
	left: 0;
	transform: translateX(-100%);
	transition: transform .3s;
	width: 320px;
	-webkit-overflow-scrolling: touch;
	@media (width >= 1200px) {
		position: static;
		transform: translate(0);
		transition: none;
		width: 100%;
		z-index: 0;
	}
	&__wrapper {
		width: 280px;
		height: 100vh;
		background-color: #f7f7f7;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
		overflow-y: scroll;
		&::-webkit-scrollbar {width: 0;}
		&::-webkit-scrollbar-track {background-color: #e5e2e1;}
		&::-webkit-scrollbar-thumb {background-color: #a7a7a7;}
		@media (width >= 1200px) {
			width: 100%;
			height: auto;
			box-shadow: none;
		}
	}
	.filter-is-open & {
		transform: translateX(0);
	}
	&__filter {
		padding: 20px;
		margin-bottom: 2px;
		display: flex;
		flex-direction: column;
		background-color: white;
		border-radius: 3px;
	}
	&__filterName {
		color: var(--black);
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 20px;
	}
	&__filterContent {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.filterLine {
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 20px;
		& > *:not(:last-child) {
			margin-right: 10px;
		}
	}
}