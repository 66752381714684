.headerInfo {
	display: flex;
	align-items: center;
	margin-left: auto;
	position: relative;	
	&__current {
		font-size: 14px;
		color: #B1B5C2;
		padding-bottom: 1px;
		cursor: pointer;
		position: relative;
		z-index: var(--dropdownTextZ);
		&:hover, .js-active & {color: var(--black);}
	}
	&__trigger {
		z-index: var(--dropdownTextZ);
	}
	&__dropdown {
		top: -10px;
		right: -15px;
		background-color: white;
		border-radius: 3px;
		box-shadow: 0 0 6px rgba(#000, .16);
		width: calc(100% + 30px);
		padding: 40px 15px 15px;
		ul {
			border-top: 1px solid #DEDEDE;
			padding-top: 10px;
			width: 100%;
		}
		li.active a {color: var(--red);}
		a {
			color: var(--black);
			transition: color .3s;
			font-size: 14px;
			text-decoration: none;
			line-height: 2;
			&:hover {color: var(--red);}
		}
	}
}