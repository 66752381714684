.modalCartTotal {
	padding-top: 25px;
	padding-bottom: 25px;
	display: flex;
	justify-content: center;
	@media (width <= 991px) {
		padding-left: 15px;
		padding-right: 15px;
	}
	&__wrapper {
		width: 100%;
		max-width: 850px;
		display: grid;
		grid-template-columns: auto max-content;
		justify-content: end;
		align-items: center;
		grid-gap: 10px;
	}
	&__title {
		color: var(--grey);
		font-size: 16px;
		font-weight: 400;
		@media (width <= 479px) {
			font-size: 14px;
		}
		&--last {
			margin-top: 10px;
		}
	}
	&__data {
		color: var(--black);
		font-size: 19px;
		font-weight: 400;
		text-align: right;
		@media (width <= 479px) {
			font-size: 16px;
		}
		&--last {
			margin-top: 10px;
			font-weight: 700;
			font-size: 21px;
			@media (width <= 479px) {
				font-size: 16px;
			}
		}
	}
}