@media (width >= 375px) {}
@media (width >= 480px) {}
@media (width >= 640px) {}
@media (width >= 768px) {}
@media (width >= 992px) {}
@media (width >= 1200px) {}
@media (width >= 1440px) {}
@media (width >= 1800px) {}
@media (width <= 1799px) {}
@media (width <= 1439px) {}
@media (width <= 1199px) {}
@media (width <= 991px) {}
@media (width <= 767px) {}
@media (width <= 639px) {}
@media (width <= 479px) {}
@media (width <= 374px) {}