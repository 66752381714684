.headerPerson {
	&__item.js-active {
		z-index: 2;
	}
	&__item.js-active::before {
		position: absolute;
		content: "";
		background-color: white;
		border-radius: 3px;
		box-shadow: 0 0 6px rgba(#000, .16);
		width: calc(100% + 20px);
		height: calc(100% + 20px);
		top: -15px;
		left: -5px;
		z-index: -1;
		@media (width >= 1200px) {
			width: calc(100% + 30px);
			height: calc(100% + 30px);
			top: -15px;
			left: -15px;
		}
	}
	&__item.js-active::after {
		position: absolute;
		content: "";
		background-color: white;
		width: calc(100% + 20px);
		height: 12px;
		left: -5px;
		bottom: -15px;
		z-index: 2;
		@media (width >= 768px) {
			.header-is-fixed & {bottom: -8px;}
		}
		@media (width >= 1200px) {
			width: calc(100% + 35px);
			height: 12px;
			left: -20px;
			bottom: -20px;
			.header-is-fixed & {
				left: -15px;
				width: calc(100% + 30px);
			}
		}
	}
	&__dropdown--wide {
		top: 25px;
		right: -15px;
		background-color: white;
		border-radius: 3px;
		width: 370px;
		max-width: calc(100vw - 10px);
		padding: 20px;
		z-index: -2!;
		display: flex;
		flex-direction: column;
		@media (width >= 768px) {
			top: 40px;
			.header-is-fixed & {
				top: 30px;
				z-index: 0 !important;
			}
		}
		@media (width >= 1200px) {top: 30px;}
		.js-active & {box-shadow: 0 0 6px rgba(#000, .16);}
	}
	&__title {
		color: var(--black);
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 15px;
		margin-top: 0;
	}
	&__text {
		margin-top: 0;
		color: var(--black);
		font-size: 16px;
		margin-bottom: 15px;
		&:last-child {margin-bottom: 0;}
		a {
			text-decoration: none;
			transition: color .3s;
			&:hover {color: var(--red);}
		}
	}
	&__dropdown--regular {
		top: 40px;
		right: -15px;
		background-color: white;
		border-radius: 3px;
		box-shadow: 0 0 6px rgba(#000, .16);
		width: calc(100% + 30px);
		min-width: 130px;
		padding: 20px;
		z-index: -2!;
		.header-is-fixed & {
			top: 30px;
		}
		@media (width >= 1200px) {
			top: 25px;
		}
		ul {
			width: 100%;
		}
		li.active a {color: var(--red);}
		a {
			color: var(--black);
			transition: color .3s;
			font-size: 14px;
			text-decoration: none;
			line-height: 2;
			&:hover {color: var(--red);}
		}
	}
}

.headerPerson__item--account.js-active {
	@media (width >= 1200px) {
		&::after {
			left: -15px;
			width: calc(100% + 30px);
			.header-is-fixed & {bottom: -18px;}
		}
	}
	@media (width <= 1199px) {
		&::before {
			left: -15px;
			width: calc(100% + 30px);
		}
		&::after {
			left: -20px;
			bottom: -17px;
			width: calc(100% + 35px);	
			.header-is-fixed & {bottom: -12px;}
		}
	}
}

