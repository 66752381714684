.catalogContent {
	width: 100%;
	border-radius: 3px;
	grid-area: catContent;
	&__itemsWrapper {
		display: grid;
		grid-gap: 2px;
		grid-template-columns: repeat(2, calc(50% - 1px));
		@media (width >= 768px) {
			height: 100%;
			grid-template-columns: repeat(3, calc(33.33% - 1px));
		}
	}
	&__noFilterResult {
		width: 100%;
		grid-column: 1 / -1;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 50px 20px;
		background-color: white;
		border-radius: 3px;
		img {
			margin-bottom: 20px;
		}
		p {
			color: var(--black);
			font-size: 26px;
			font-weight: 400;
			line-height: 36px;
			text-align: center;
			width: 100%;
			max-width: 750px;
		}
	}
}