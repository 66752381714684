.hasBtnTooltip {
	position: relative;
}

.btnTooltip {
	position: absolute;
	width: 240px;
	border-radius: 10px;
	background-color: #797979;
	padding: 10px;
	color: white;
	top: 40px;
	left: 50%;
	transform: translate(-50%,5px);
	transition: opacity .3s, transform .3s;
	opacity: 0;
	pointer-events: none;
	&::after {
		position: absolute;
		display: block;
		content: "";
		top: -9px;
		left: calc(50% - 10px);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 10px 10px 10px;
		border-color: transparent transparent #797979 transparent;

	}
	a {
		font-weight: bold;
		padding-left: 5px;
		color: white;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

.hasBtnTooltip:hover .btnTooltip {
	opacity: 1;
	pointer-events: all;
	transform: translate(-50%,0);
}