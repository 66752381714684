.catalogFirstLine {
	background-color: #FFF;
	display: flex;
	flex-direction: column;
	@media (width >= 768px) {
		display: grid;
		grid-template-columns: auto 280px 92px;
		grid-template-areas:
		"flTitle flTitle flTitle"
		"flBtn flSorting flTogglers";
		grid-column-gap: 20px;
		align-items: center;
	}
	@media (width >= 992px) {
		grid-template-columns: auto 300px 92px;
	}
	@media (width >= 1200px) {
		background-color: #F3F3F3;
		grid-template-areas:
		"flTitle flSorting flTogglers";
	}
}