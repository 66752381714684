.profitableOffers {
	margin-bottom: 25px;
	&__itemsWrapper {
		display: grid;
		grid-gap: 2px;
		grid-template-columns: repeat(2, calc(50% - 1px));
		@media (width >= 768px) {
			grid-template-columns: repeat(3, calc(33.33% - 1px));
		}
		@media (width >= 1200px) {
			grid-template-columns: repeat(4, calc(25% - 1px));
		}
	}
	&__btnWrapper {
		display: flex;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		justify-content: center;
		align-items: center;
		background-color: white;
		margin-top: 2px;
	}
}