.catalogFilters {
	.subFilter {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		a {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 0.14px;
			line-height: 32px;
			text-decoration: none;
			transition: color .3s;
			&:hover,&.active {
				color: #ff1b32;
			}
		}
	}
}