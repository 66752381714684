.headerLogo {
	grid-area: hLogo;
	display: flex;
	justify-content: center;
	@media (width >= 768px) {
		flex-basis: auto;
		padding-left: 20px;
	}
	@media (width >= 1200px) {
		padding-left: 0;
		justify-content: start;
	}
	&__link {
		display: flex;
		align-items: center;
		path {transition: fill .3s;}
	}
	picture,img {
		display: block;
		width: auto;
		height: 26px;
		margin-left: 5px;
	}
	@media (width >= 768px) {
/* 		svg {
			width: 54px;
			height: auto;
		} */
		path {fill: var(--black);}
		picture,img {height: 39px;}
	}
	@media (width >= 1200px) {
		/* svg {width: 58px;} */
		picture,img {height: 55px;}
	}
}