.checkout-success {
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #f3f3f3;
	display: grid;
	grid-gap: 40px;
	@media (width <= 1199px) {
		padding-left: 15px;
		padding-right: 15px;
	}
	@media (width >= 992px) {
		grid-template-columns: 1fr 380px;
		grid-template-areas:
		"checkoutTop checkoutTop"
		"checkoutLeft checkoutRight"
		"checkoutBottom checkoutBottom";
		align-items: start;
	}
	&__top {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 992px) {
			grid-area: checkoutTop;
		}
		img {
			width: 50px;
			height: 50px;
			margin-bottom: 25px;
		}
		h1 {
			color: var(--black);
			font-size: 30px;
			font-weight: 400;
			margin-top: 0;
			margin-bottom: 10px;
		}
		p {
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 0;
		}
		a {
			color: #2b6bdd;
			text-decoration: none;
		}
	}
	&__left {
		background-color: white;
		padding: 15px;
		@media (width >= 992px) {
			grid-area: checkoutLeft;
			padding: 40px;
		}
		ul:not(:last-child) {
			margin-bottom: 15px;
			@media (width >= 992px) {
				margin-bottom: 40px;
			}
		}
		dl {
			margin-top: 0;
			margin-bottom: 10px;
			@media (width <= 767px) {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			@media (width >= 768px) {
				display: grid;
				grid-template-columns: 230px 1fr;
				grid-gap: 30px;
			}
		}
		dt, dd {
			color: var(--black);
			font-size: 16px;
			line-height: 1.5;
		}
		dt {
			font-weight: 700;
		}
		dd {
			margin-left: 0;
		}
		h2 {
			margin-top: 0;
			margin-bottom: 30px;
			font-size: 21px;
		}
		h3 {
			margin-top: 0;
			margin-bottom: 0;
			font-size: 21px;
		}
	}
	&__right {
		background-color: white;
		padding: 15px;
		@media (width >= 992px) {
			grid-area: checkoutRight;
			padding: 40px;
		}
		h2 {
			margin-top: 0;
			margin-bottom: 30px;
			font-size: 21px;
		}
		.checkout-items {
			li {
				padding-bottom: 20px;
				margin-bottom: 20px;
				border-bottom: 1px solid #f3f3f3;
			}
			a {
				text-decoration: none;
				color: var(--black);
				transition: opacity .3s;
				@media (width >= 480px) {
					display: flex;
					align-items: flex-start;
				}
				&:hover {opacity: 0.7;}
				img {margin-right: 20px;}
			}
			.checkout-item__details {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
			}
			.checkout-item__heading {
				color: var(--black);
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
			}
			.checkout-item__subheading {
				color: var(--grey);
				font-size: 14px;
				font-weight: 400;
				letter-spacing: 0.14px;
				line-height: 22px;
			}
			.checkout-item__footer {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.checkout-item__price {
				color: var(--black);
				font-size: 19px;
				font-weight: 400;
				letter-spacing: 0.19px;
				line-height: 21px;
			}
			.checkout-item__qty {
				color: var(--black);
				font-size: 14px;
				font-weight: 400;
				letter-spacing: 0.14px;
				line-height: 21px;
			}
		}
		.checkout-results {
			padding-top: 20px;
			li:not(:last-child) {
				margin-bottom: 15px;
			}
			dl {
				margin-top: 0;
				margin-bottom: 0;
				@media (width >= 480px) {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
				}
			}
			dt {
				color: var(--grey);
				font-size: 16px;
				font-weight: 400;
				letter-spacing: 0.16px;
			}
			dd {
				color: var(--black);
				font-size: 16px;
				font-weight: 400;
				letter-spacing: 0.16px;
				margin-left: 0;
				padding-left: 15px;
			}
			.color-red {
				color: #ff1b32;
			}
			.color-green {
				color: #00a705;
			}
		}
	}
	&__bottom {
		@media (width >= 992px) {
			grid-area: checkoutBottom;
		}
		a {
			display: flex;
			align-items: center;
			text-decoration: none;
			span {
				color: var(--blue);
				font-size: 14px;
				font-weight: 400;
				margin-left: 10px;
				border-bottom: 1px solid transparent;
				transition: border-bottom-color .3s;
				&:hover {
					border-bottom-color: currentColor;
				}
			}
		}
	}
}