.sectionTitle {
	font-size: 12px;
	font-weight: bold;
	color: var(--black);
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	text-transform: uppercase;
	text-align: center;
	@media (width >= 768px) {
		font-size: 18px;
	}
	@media (width >= 1200px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}