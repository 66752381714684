.contactsDetails {
	&__wrapper {
		@media (width >= 768px) {
			display: grid;
			grid-template-columns: 360px 1fr;
			grid-gap: 40px 30px;
			padding-bottom: 10px;
		}
	}
	&__item {
		@media (width <= 767px) {
			padding-top: 20px;
			padding-bottom: 20px;
			position: relative;
			&::before, &::after {
				position: absolute;
				display: block;
				content: "";
				width: calc(100% + 40px);
				height: 1px;
				left: -20px;
				background-color: #f3f3f3;
			}
			&::before {top: 0;}
			&::after {bottom: 0;}
		}
		li {
			&:not(:last-child) {margin-bottom: 10px;}
		}
		a {
			display: flex;
			align-items: center;
			transition: opacity .3s!;
			border-bottom: none!;
			&:hover {opacity: 0.7;}
			span {margin-left: 10px;}
		}
		&--wide {
			@media (width <= 767px) {
				padding-top: 30px;
				padding-bottom: 30px;
				&::before, &::after {
					height: 10px;
				}
			}
			@media (width >= 768px) {
				grid-column: 1 / -1;
				display: grid;
				grid-template-columns: 360px 1fr;
				grid-column-gap: 30px;
				align-items: center;
				h3 {
					grid-column: 1 / -1;
				}
			}
		}
		&--tall {
			@media (width >= 768px) {
				grid-row: span 2;
			}
		}
		h3 {
			color: var(--black);
			font-weight: 700;
			margin-top: 0;
			margin-bottom: 15px;
			@media (width <= 767px) {font-size: 16px;}
			@media (width >= 768px) {font-size: 18px;}
		}
	}
	.contactsDays {
		@media (width <= 767px) {display: none;}
		@media (width >= 768px) {
			border-radius: 3px;
			background-color: #f7f7eb;
			padding: 20px 25px;
			display: inline-flex;
			align-items: flex-start;
			li {
				color: var(--black);
				font-size: 16px;
				font-weight: 400;
				padding-bottom: 10px;
				margin-bottom: 0;
				position: relative;
				&::after {
					position: absolute;
					display: block;
					content: "";
					width: 5px;
					height: 5px;
					border-radius: 50%;
					bottom: -2px;
					left: 50%;
					transform: translateX(-50%);
				}
				&:not(:last-child) {margin-right: 30px;}
			}
			&__item::after {background-color: #979a9a;}
			&__item--on::after {background-color: #00a705;}
		}
	}
	.contactsTime {
		li {
			display: flex;
			align-items: center;
			&:not(:last-child) {margin-bottom: 10px;}
			span {margin-left: 10px;}
		}
	}
	.contactsSocial {
		display: flex;
		align-items: center;
		li {
			margin-bottom: 0 !important;
			&:not(:last-child) {margin-right: 25px;}
		}
	}
}