.textMenu {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #f3f3f3;
	position: relative;
	z-index: 20;
	@media (width >= 1200px) {
		position: sticky;
		top: 90px;
	}
	@media (width >= 1440px) {
		top: 80px;
	}
	h1 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		background-color: white;
		padding: 15px;
		height: 70px;
		margin-top: 0;
		margin-bottom: 0;
		user-select: none;
		cursor: pointer;
		@media (width >= 1200px) {
			visibility: hidden;
			position: absolute;
			width: 1px;
			height: 1px;
			margin: -1px;
			pointer-events: none;
			clip: rect(0 0 0 0);
		}
		span {
			color: var(--black);
			font-size: 21px;
			font-weight: 700;
			padding-left: 15px;
			padding-right: 5px;
		}
		.icon-arrow {
			width: 44px;
			height: 44px;
			background-image: url(../img/account/accountMenuArrow.svg);
			background-size: 20px 12px;
			background-position: center;
			background-repeat: no-repeat;
			transition: transform .3s;
		}
		&.is-open .icon-arrow{
			transform: rotateX(180deg);
		}
	}
	ul {
		@media (width <= 1199px) {
			position: absolute;
			top: 70px;
			width: 100%;
		}
		li {
			border-bottom: 1px solid #e8e8e9;
			@media (width >= 1200px) {
				border-bottom-color: #f3f3f3;
			}
		}
		a {
			font-size: 16px;
			font-weight: 400;
			min-height: 75px;
			display: flex;
			align-items: center;
			padding: 5px 25px;
			color: var(--black);
			background-color: #f7f6f5;
			transition: background-color .3s, color .3s;
			text-decoration: none;
			@media (width >= 1200px) {
				background-color: white;
			}
			&.active {
				background-color: #ff1b32;
				color: white;
			}
			&:hover:not(.active) {
				background-color: #ff5062;
				color: white;
			}
			img {
				transition: filter .3s;
			}
			&.active img,
			&:hover img {
				filter: contrast(5);
			}
		}
		.icon {
			width: 44px;
			height: 44px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 20px;
		}
		@media (width <= 1199px) {
			pointer-events: none;
			opacity: 0;
			transform: translateY(10px);
			transition: opacity .3s, transform .3s;
		}
	}
	.is-open + ul {
		@media (width <= 1199px) {
			opacity: 1;
			transform: translateY(0);
			pointer-events: all;
		}
	}
}