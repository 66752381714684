.itemMainFave {
	button {
		width: 40px;
		height: 40px;
		appearance: none;
		background-color: transparent;
		background-image: none;
		padding: 0;
		border: none;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #b1b5c2;
		transition: color .3s;
		@media (width >= 768px) {
			/* width: auto; */
			margin-right: 15px;
		}
		&:hover {
			color: var(--red);
		}
	}
}