.headerSearch {
	grid-area: hSearch;
	position: relative;
	z-index: 2;
	&__form {
		width: 100%;
		height: 40px;
		background-color: white;
		display: flex;
		transition: box-shadow .3s, border-color .3s;
		border: 1px solid #E8E8E9;
		border-radius: 3px;
		@media (width >= 768px) {
			border-radius: 0;
			background-color: #F3F3F3;
			border-color: #F3F3F3;
			height: 60px;
		}
		@media (width >= 1200px) {
			height: 45px;
			border-radius: 3px;
			border-color: #E8E8E9;
			background-color: white;
		}
		&:focus-within {
			box-shadow: inset 0 0 1px 2px #ED8A19;
			border-color: #ED8A19;
		}
	}
	&__input {
		padding: 0 0 0 10px;
		background-color: transparent;
		width: calc(100% - 45px);
		height: 100%;
		font-size: 13px;
		border: none;
		display: flex;
		align-items: center;
		appearance: none;
		outline: none;
		color: var(--black);
		caret-color: var(--black);
		@media (width >= 768px) {
			font-size: 16px;
			padding-left: 20px;
			width: calc(100% - 55px);
		}
		&::-webkit-input-placeholder {color: var(--grey);}
		&::-moz-placeholder {color: var(--grey);}
		&::-ms-input-placeholder {color: var(--grey);}
	}
	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		appearance: none;
		border: none;
		height: 100%;
		width: 45px;
		outline: none;
		background-color: transparent;
		cursor: pointer;
		@media (width >= 768px) {
			margin-right: 10px;
		}
		path {
			@media (width >= 768px) {
				fill: var(--black);
			}
		}
	}
	&__resultBox {
		display: none;
		position: absolute;
		top: 40px;
		left: 0;
		background-color: white;
		@media (width >= 768px) {top: 60px;}
		@media (width >= 1200px) {top: 45px;}
		.header-is-fixed & {top: 50px;}
		&.js-active {
			display: flex;
			flex-direction: column;
			width: 100%;
			border: 1px solid #B1B5C2;
			border-radius: 3px;
		}
	}
	&__resultItems {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	&__result {
		width: 100%;
		display: grid;
		grid-template-columns: 45px 1fr max-content max-content;
		grid-template-areas:
		"rImg rName rName rName"
		"rImg rPrice rPrice rOld";
		grid-gap: 10px 15px;
		align-items: center;
		padding: 10px;
		border-bottom: 1px solid #B1B5C2;
		text-decoration: none;
		transition: background-color .3s;
		&:hover {background-color: #F3F3F3;}
		@media (width >= 768px) {
			grid-template-areas:
			"rImg rName rPrice rOld";
		}
	}
	&__resultImg {
		grid-area: rImg;
		width: 45px;
		height: 45px;
		img {
			width: 100%;
			height: auto;
		}
	}
	&__resultName {
		grid-area: rName;
		font-size: 16px;
		color: var(--blue);
	}
	&__price {
		grid-area: rPrice;
		justify-self: end;
		font-size: 19px;
		font-weight: 500;
		color: var(--red);
	}
	&__old {
		grid-area: rOld;
		color: var(--grey);
		font-size: 16px;
		font-weight: 500;
		text-decoration: line-through;
	}
	&__resultAll {
		transition: background-color .3s;
		&:hover {background-color: #F3F3F3;}
		a {
			padding: 20px 15px;
			display: flex;
			align-items: center;
			text-decoration: none;
			color: var(--blue);
			font-size: 16px;
			img {margin-left: 10px;}
		}
	}
}

.js-overlay--search {
	.headerSearch {
		@media (width >= 1200px) {margin-right: -450px;}	
		@media (width >= 1440px) {margin-right: -440px;}
		&::before {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			content: "";
			background: rgba(#fff, 0.8);
			z-index: -1;
		}
	}
}

.js-overlay--search.header-is-fixed {
	.headerSearch {
		@media (width >= 1200px) {margin-right: -435px;}	
		@media (width >= 1440px) {margin-right: -350px;}
		@media (width >= 1800px) {margin-right: -385px;}
	}
}