.catDirections {
	position: relative;
	display: grid;
	grid-gap: 2px;
	grid-template-columns: repeat(2, calc(50% - 1px));
	grid-template-rows: repeat(2, 1fr);
	@media (width >= 640px) {
		grid-template-columns: repeat(4, calc(25% - 1px));
	}
	@media (width >= 992px) {
		display: block;
	}
	@media (width >= 1440px) {
		display: grid;
		grid-template-columns: 50px 1fr;
		grid-column-gap: 50px;
		align-items:  start;
		margin-left: -100px;
		width: calc(100% + 100px);
	}
}