.siteHeader {
	position: relative;
	background-color: white;
	padding: 10px;
	z-index: 21;
	@media (width >= 768px) {
		border-top: 60px solid var(--beige);
		padding: 20px 0;
	}
	@media (width >= 992px) {padding-bottom: 10px;}
	@media (width >= 1200px) {padding: 15px 0;}
	@media (width >= 1440px) {
		padding-bottom: 0;
		position: relative;
		&::before {
			.page--index & {
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 60px;
				background-color: var(--bodyBG);
				bottom: 0;
			}
		}
	}
	@media (width <= 767px) {
		z-index: 31;
		@supports (display: contents) {
			display: contents;
			.container {
				padding: 10px;
			}
		}
	}
	&__wrapper {
		position: relative;
		z-index: 1;
		display: grid;
		grid-template-columns: 50px 1fr 50px;
		grid-row-gap: 10px;
		grid-template-areas: 
		"hButton hLogo hPerson"
		"hSearch hSearch hSearch"
		"hCatalogue hCatalogue hCatalogue";
		@media (width >= 768px) {
			grid-template-columns: 80px 80px 30px 1fr 190px;
			grid-row-gap: 20px;
			grid-template-areas: 
			"hLogo hLogo hLogo hTopLeft hPerson"
			"hCatalogue hBrands hSearch hSearch hSearch";
		}
		@media (width >= 1200px) {
			grid-template-columns: 270px 1fr 100px 350px;
			grid-row-gap: 15px;
			grid-template-areas: 
			"hLogo hTopLeft hTopLeft hTopRight"
			"hLogo hSearch hPerson hPerson"
			"hCatalogue hBrands hBrands hBrands"
		}
		@media (width >= 1440px) {
			grid-template-columns: 270px 1fr 440px;
			grid-template-areas: 
			"hLogo hTopLeft  hTopRight"
			"hLogo hSearch  hPerson"
			"hCatalogue hBrands hBrands"
		}
	}
	&__headerBar {
		display: none;
		&--topLeft {
			grid-area: hTopLeft;
			@media (width >= 768px) {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
			@media (width >= 992px) {justify-content: center;}
			@media (width >= 1200px) {justify-content: flex-start;}
		}
		&--topRight {
			grid-area: hTopRight;
			@media (width >= 1200px) {
				display: flex;
			}
		}
	}
}