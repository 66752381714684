.article-page .siteMain {
	background-color: white;
}

.article-wrapper {
	display: grid;
	grid-template-columns: 40px 780px 40px;
	grid-column-gap: 30px;
	justify-items: center;
	justify-content: center;
	align-items: start;
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
	@media (width <= 991px) {
		display: block;
	}
}

.article-content {
	grid-area: 1/2/2/3;
	width: 100%;
	@media (width <= 767px) {
		padding-top: 0;
		padding-bottom: 0;
	}
	
	date {
		display: block;
		color: #b1b5c2;
		font-size: 21px;
		font-weight: 400;
		line-height: 22px;
		text-align: center;
		margin-bottom: 30px;
		@media (width <= 767px) {
			margin-bottom: 15px;
		}
	}

	h1 {
		color: var(--black);
		font-size: 42px;
		font-weight: 400;
		letter-spacing: 0.5px;
		line-height: normal;
		margin-top: 0;
		margin-bottom: 40px;
		text-align: center;
		@media (width <= 767px) {
			font-size: 30px;
		}
	}

	img {
		display: block;
		max-width: 100%;
		height: auto;
		margin-left: auto;
		margin-right: auto;
	}

}

.article-social {
	@media (width >= 992px) {
		grid-area: 1/1/2/2;
		position: sticky;
		top: 80px;
		margin-top: 100px;
		.social__list {
			flex-direction: column;
		}
		.social__item:not(:last-child) {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
	@media (width <= 991px) {
		padding-left: 20px;
		padding-right: 20px;
		.social {
			align-items: center;
		}
	}
}

.share-wrapper {
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	> span {
		color: var(--black);
		font-size: 18px;
		font-weight: 700;
		margin-right: 20px;
	}
}