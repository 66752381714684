.description {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	&__wrapper {
		width: 100%;
		background-color: #FFF;
		padding: 30px 10px;
		@media (width >= 768px) {
			padding: 40px 20px;
		}
	}
	&__heading {
		color: var(--black);
		font-size: 21px;
		font-weight: 400;
		line-height: 30px;
		margin-bottom: 20px;
		display: flex;
		width: 100%;
	}
	&__text {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		p {
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}