.mMenuSecond {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #E0E0E0;
	background-color: #F3F3F3;
	li {width: 100%;}
	a {
		text-decoration: none;
		color: var(--black);
		display: flex;
		padding: 15px;
		justify-content: center;
		align-items: center;
		background-color: transparent;
		width: 100%;
		transition: background-color .3s, color .3s;
		&:hover {
			background-color: white;
			color: var(--red);
		}
	}
}