.checkoutTabs {
	grid-area: cTabs;
	padding-top: 5px;
	padding-bottom: 5px;
	&__tabs {
		display: flex;
		li:not(:first-child) {margin-left: 15px;}
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			background-color: transparent;
			color: var(--blue);
			padding: 5px 10px;
			border-radius: 10px;
			text-decoration: none;
			transition: color .3s, background-color .3s;
			&:hover,&.active {
				color: var(--black);
				background-color: #FBE3C8;
			}
		}
	}
}