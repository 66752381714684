.itemMainId {
	display: flex;
	width: 100%;
	height: 50px;
	align-items: center;
	justify-content: flex-start;
	@media (width >= 768px) {
		justify-content: flex-end;
	}
	span {
		color: var(--black);
		font-size: 13px;
		font-weight: 400;
		letter-spacing: 0.13px;
		line-height: 20px;
	}
}