.indexAdvantages {
	display: none;
	background-color: white;
	@media (width >= 768px) {
		display: flex;
		padding: 30px 20px;
		justify-content: space-between;
		align-items: flex-start;
	}
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 1200px) {flex-direction: row;}
	}

	&__itemImg {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
		@media (width >= 1200px) {
			margin-bottom: 0;
			margin-right: 20px;
		}
	}
	&__itemText {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 1200px) {align-items: flex-start;}
	}
	&__itemHeading {
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		color: var(--black);
		font-size: 18px;
		font-weight: bold;
		@media (width >= 1200px) {margin-bottom: 0;}
	}
	&__itemSubheading {
		display: block;
		margin-top: 0;
		margin-bottom: 0;
		color: #707070;
		font-size: 14px;
	}
}