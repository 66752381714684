.modalAccount {
	display: flex;
	flex-direction: column;
	align-items: center;
	&__heading {
		color: var(--dark);
		font-size: 16px;
		margin-top: 0;
		margin-bottom: 20px;
	}
	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	&__formgroup {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 15px;
	}
	input[type="email"],
	input[type="password"],
	input[type="text"] {
		width: 100%;
		height: 46px;
		border-radius: 3px;
		border: 1px solid #E8E8E9;
		padding: 10px 65px;
		outline: none;
		appearance: none;
		caret-color:  var(--green);
		color: var(--blue);
		font-size: 16px;
		transition: border-color .3s;
		&::-webkit-input-placeholder {color: var(--grey);}
		&::-moz-placeholder {color: var(--grey);}
		&::-ms-input-placeholder {color: var(--grey);}
		&:focus {border-color: var(--blue);}
		&.error {border-color: var(--red);}
	}

	input[type="submit"] {
		width: 100%;
		height: 46px;
		outline: none;
		appearance: none;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		font-size: 16px;
		background-color: var(--green);
		border: 1px solid var(--green);
		color: white;
		cursor: pointer;
		transition: opacity .3s;
		&:hover {opacity: 0.7;}
	}

	.secondary {
		text-decoration: none;
		width: 100%;
		height: 46px;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		font-size: 16px;
		background-color: white;
		border: 1px solid var(--black);
		color: var(--black);
		transition: opacity .3s;
		&:hover {opacity: 0.7;}
	}

	label.error {
		color: var(--red);
		line-height: 2;
		font-weight: 400;
	}

	&__recover {
		text-decoration: none;
		color: var(--blue);
		display: flex;
		text-align: center;
		justify-content: center;
		line-height: 2;
	}

	&__divider {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 15px;
		padding-bottom: 15px;
		i {
			display: block;
			height: 1px;
			background-color: #B1B1B1;
			flex-basis: 10px;
			flex-grow: 1;
		}
		span {
			color: var(--black);
			font-size: 14px;
			text-transform: uppercase;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.tip {
		position: relative;
		&::after {
			position: absolute;
			display: block;
			width: 50px;
			height: 46px;
			content: "";
			background-repeat: no-repeat;
			background-position: center;
			background-size: 20px 20px;
		}
	}
	.tip--login::after {background-image: url(../img/icon--email.svg);}
	.tip--password::after {background-image: url(../img/icon--pass.svg);}
	.icon {
		position: relative;
		&::after {
			position: absolute;
			display: block;
			width: 50px;
			height: 46px;
			content: "";
			background-repeat: no-repeat;
			background-position: center;
			background-size: 20px 20px;
		}
		input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&::before {
			display: block;
			position: absolute;
			content: "";
			width: 50px;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(#000, .15);
			z-index: 1;
		}
	}
	&__loginFB,&__loginG {
		width: 100%;
		height: 46px;
		background-color: var(--accentColor);
		border: 1px solid var(--accentColor);
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		padding-left: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: 16px;
		font-weight: 700;
		text-decoration: none;
		transition: opacity .3s;
		&:hover {
			opacity: 0.7;
			color: white;
		}
	}
	&__loginFB {--accentColor: #3C539B;}
	&__loginG {--accentColor: #437BF9;}

	.icon--login::after {background-image: url(../img/icon--login.svg);}
	.icon--fb::after {background-image: url(../img/icon--fb.svg);}
	.icon--gp::after {background-image: url(../img/icon--gp.svg);}

	.checkbox {
		flex-direction: row;
		align-items: center;
		position: relative;
		input {
			opacity: 0;
			position: absolute;
			cursor: pointer;
			width: 20px;
			height: 100%;
		}
		i {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 16px;
			height: 16px;
			border-radius: 3px;
			background-color: var(--blue);
			flex-shrink: 0;
			position: relative;
			pointer-events: none;
			cursor: pointer;
		}
		input:checked + i::after {
			content: "✓";
			color: white;
			font-size: 12px;
			cursor: pointer;
		}
		label {
			color: var(--black);
			font-size: 14px;
			margin-left: 10px;
			cursor: pointer;
		}
	}
	&__showPass {
		position: absolute;
		display: block;
		width: 50px;
		height: 46px;
		content: "";
		top: 0;
		right: 0;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 17px 11px;
		background-image: url(../img/icon--showPass.svg);
	}
}