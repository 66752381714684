.footerLogo {
	display: none;
	grid-area: fLogo;
	@media (width >= 768px) {
		display: flex;
		flex-basis: auto;
		transition: opacity .3s;
		&:hover {opacity: 0.7;}
	}
	@media (width >= 1200px) {
		padding-left: 0;
		justify-content: start;
	}
	&__link {
		display: flex;
		align-items: center;
		path {transition: fill .3s;}
	}
	picture,img {
		display: block;
		width: auto;
		height: 13px;
		margin-left: 5px;
	}
	@media (width >= 768px) {
		svg {
			width: 54px;
			height: auto;
		}
		path {fill: var(--black);}
		picture,img {height: 39px;}
	}
	@media (width >= 1200px) {
		svg {width: 58px;}
		picture,img {height: 42px;}
	}
}
