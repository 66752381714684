.itemPageHeading {
	color: var(--black);
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	h1 {
		font-size: 30px;
		font-weight: 700;
	}
	@media (width <= 1199px) {
		padding-left: 20px;
		padding-right: 20px;
		h1 {
			font-size: 26px;
		}
	}
	@media (width <= 767px) {
		padding-left: 10px;
		padding-right: 10px;
		margin-top: 0;
		margin-bottom: 0;
		h1 {
			font-size: 16px;
		}
	}
}