.checkoutConfirm {
	grid-area: cConfirm;
	display: grid;
	grid-template-columns: 1fr 400px;
	grid-gap: 30px;
	@media (width <= 767px) {
		justify-items: center;
		grid-template-columns: 1fr;
		grid-gap: 10px;
	}
	&__btn {
		width: 400px;
		max-width: 100%;
		height: 64px;
		border-radius: 3px;
		background-color: var(--green);
		border: 1px solid var(--green);
		color: white;
		font-family: Helvetica;
		font-size: 21px;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		appearance: none;
		cursor: pointer;
		transition: background-color .3s, color .3s;
		@media (width <= 479px) {
			width: 100%;
		}
		&:hover {
			background-color: white;
			color: var(--green);
		}
	}
}