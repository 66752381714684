.category-wrapper {
	padding-top: 30px;
	padding-bottom: 30px;
	@media (width <= 1199px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	&__heading {
		color: var(--black);
		font-size: 24px;
		font-weight: 700;
		letter-spacing: 0.24px;
		margin-top: 0;
		margin-bottom: 20px;
	}
	&__grid {
		width: 100%;
		display: grid;
		grid-template-columns: repeat( auto-fit, minmax(280px, 1fr) );
		grid-gap: 10px;
	}
}