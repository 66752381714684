.textblock {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		width: 100%;
		padding: 50px 10px;
		@media (width >= 1200px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&__heading {
		font-size: 16px;
		font-weight: bold;
		color: var(--black);
		margin-bottom: 20px;
		@media (width >= 768px) {
			font-size: 21px;
			margin-bottom: 30px;
		}
	}
	&__text {
		font-size: 12px;
		color: var(--black);
		@media (width >= 768px) {
			font-size: 16px;
		}
		p {
			margin-bottom: 10px;
		}
	}
	&__trigger {
		width: 40px;
		height: 40px;
		display: flex;
		border: 1px solid #95989A;
		border-radius: 50%;
		position: relative;
		@media (width >= 768px) {
			position: absolute;
			top: 42px;
			right: 10px;
		}
		&::before,&::after {
			width: 20px;
			height: 2px;
			background-color: #95989A;
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&::before {
			transform: translate(-50%, -50%);
		}
		&::after {
			transform: translate(-50%, -50%) rotate(90deg);
			transition: transform .3s;
		}
		&.active::after {
			transform: translate(-50%, -50%) rotate(0); 
		}
	}
}