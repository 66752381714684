.itemMainBonuses {
	position: relative;
	display: flex;
	align-items: center;
	width: 170px;
	margin-left: auto;
	&__current {
		display: flex;
		align-items: center;
		font-size: 14px;
		flex-direction: column;
		margin-left: 5px;
		margin-right: 5px;
		b {
			font-size: 14px;
			font-weight: bold;
			color: #00a705;
		}
		span {
			color: var(--black);
			margin-left: 5px;
		}
	}
	&__trigger {
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #b1b5c2;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 5px;
		span {
			color: #b1b5c2;
			font-size: 12px;
		}
	}
	&__dropdown {
		top: 15px;
		left: 26px;
		padding-top: 20px;
		min-width: 140px;
	}
	&__content {
		background-color: white;
		border: 1px solid #e8e8e9;
		padding: 10px;
		border-radius: 3px;
		font-size: 13px;
		line-height: 1.5;
		color: var(--black);
		position: relative;
		max-width: 140px;
		&::before {
			position: absolute;
			content: "";
			width: 10px;
			height: 10px;
			border-left: 1px solid #e8e8e9;
			border-top: 1px solid #e8e8e9;
			top: -5px;
			right: 13px;
			transform: rotate(45deg);
			background-color: white;
		}
		a {
			text-decoration: none;
		}
	}
}