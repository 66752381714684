.catalogue {
	--catalogueHeight: auto;
	--catalogueTop: auto;
	&__subBox {
		@media (width <= 1199px) {display: none!;}
		.dropdown.active &,
		.dropdown:focus &,
		.dropdown:hover & {
			position: fixed;
			top: 0;
			min-height: 100%;
			left: 270px;
			width: calc(var(--containerWidth) - 270px);
			box-shadow: 0 0 6px rgba(#000, .16);
			padding: 30px;
			background-color: white;
			column-count: 4;
			column-fill: balance;
			column-gap: 30px;
		}
	}
	&__subBoxList {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 10px;
		break-inside: avoid-column;
	}
	&__subBoxHeading {
		width: 100%;
		font-size: 15px;
		font-weight: bold;
		color: var(--black);
		transition: color .3s;
		text-decoration: none;
		margin-bottom: 10px;
		&:hover {color: var(--blue);}
	}
	&__subBoxLink {
		width: 100%;
		font-size: 15px;
		color: var(--black);
		transition: color .3s;
		text-decoration: none;
		margin-bottom: 10px;
		&:hover {color: var(--red);}
	}
}