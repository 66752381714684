.mMenu {
	display: none;
	@media (width <= 767px) {
		.menu-is-open & {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 31;
			max-height: 100vh;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			animation: mMenuShadow .3s .3s forwards;
			
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		width: 280px;
		background-color: #FFF;		
		transform: translateX(-100%);
		animation: mMenuReveal .3s forwards;
	}
}
@keyframes mMenuReveal {
	from {transform: translateX(-100%);}
	to {transform: translateX(0);}
}

@keyframes mMenuShadow {
	from {box-shadow: 0 0 0 rgba(#000, .16);}
	to {box-shadow: 0 0 6px rgba(#000, .16);}
}