.accountMain--purchase-history {
	.purchase-history__heading {
		padding: 20px;
		background-color: white;
		@media (width >= 768px) {
			padding: 25px 40px;
		}
		h2 {
			color: var(--black);
			font-size: 16px;
			font-weight: 700;
			margin-top: 20px;
			margin-bottom: 20px;
			@media (width >= 768px) {
				font-size: 24px;
			}
			@media (width >= 992px) {
				text-align: left;
			}
		}
		.titles {
			display: none;
			@media (width >= 992px) {
				display: grid;
				grid-template-columns: 120px 140px 140px 1fr;
				grid-column-gap: 30px;
				width: 100%;
				align-items: center;
			}
			li {
				color: var(--grey);
				font-family: Helvetica;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
	.accordion-title {
		padding: 20px 20px 0;
		background-color: white;
		display: flex;
		flex-direction: column;
		border: 1px solid #e6e6db;
		background-color: #f7f7eb;
		@media (width >= 768px) {
			padding: 25px 40px 0;
		}
		@media (width >= 992px) {
			flex-direction: row;
			padding-bottom: 25px;
		}
		ul {
			margin-top: 0;
			margin-bottom: 0;
			padding-left: 0;
			list-style: none;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
			"ph1 ph4"
			"ph2 ph4"
			"ph3 ph4";
			grid-gap: 15px 20px;
			@media (width >= 480px) {
				grid-template-columns: 1fr auto;
				grid-template-areas:
				"ph1 ph3"
				"ph2 ph4";
			}
			@media (width >= 768px) {
				grid-template-columns: 200px 200px 1fr;
				grid-template-areas:
				"ph1 ph3 ph4"
				"ph2 ph3 ph4";
				grid-column-gap: 40px;
			}
			@media (width >= 992px) {
				grid-template-columns: 120px 140px 140px 1fr;
				grid-column-gap: 30px;
				grid-template-areas:"ph1 ph2 ph3 ph4";
			}
			li {
				display: flex;
				width: 100%;
				flex-direction: column;
				align-items: flex-start;
				&:nth-child(1) {grid-area: ph1;}
				&:nth-child(2) {grid-area: ph2;}
				&:nth-child(3) {grid-area: ph3;}
				&:nth-child(4) {grid-area: ph4;}
			}
			small {
				color: var(--grey);
				font-size: 14px;
				line-height: 1.5;
				font-weight: 400;
				@media (width >= 992px) {
					display: none;
				}
			}
			p {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				align-items: flex-start;
				color: var(--black);
				font-size: 14px;
				line-height: 1.5;
				font-weight: 400;
				> *:not(:last-child) {
					margin-right: 5px;
				}
			}
			u {
				text-decoration: line-through;
				color: var(--grey);
			}
			mark {
				background: none;
				color: #ff1b32;
			}
		}
		.accordion-title__trigger {
			width: 100%;
			height: 44px;
			background-size: 14px 8px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(../img/account/accordion-title-trigger.svg);
			transition: transform .3s;
			margin-left: auto;
			margin-right: auto;
			cursor: pointer;
			@media (width >= 992px) {
				width: 44px;
			}
		}
		&.is-open .accordion-title__trigger {
			transform: rotateX(180deg);
		}
	}
	.accordion-content {
		background-color: #e6e6db;
		padding-left: 20px;
		padding-right: 20px;
		overflow: hidden;
		opacity: 0;
		max-height: 0;
		transition: opacity .3s;
		@media (width >= 768px) {
			padding-left: 40px;
			padding-right: 40px;
		}
	}
	.is-open + .accordion-content {
		opacity: 1;
		max-height: none;
		padding-top: 20px;
		padding-bottom: 20px;
		@media (width >= 768px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
	.accordion-content__item {
		margin-top: 0;
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-areas:
		"ac1 ac1"
		"ac2 ac3";
		grid-gap: 15px 40px;
		&:not(:last-child) {
			border-bottom: 1px solid white;
			padding-bottom: 15px;
			margin-bottom: 15px;
		}
		@media (width >= 768px) {
			grid-template-columns: 200px 200px 1fr;
			grid-template-areas: "ac1 ac3 ac2";
		}
		@media (width >= 992px) {
			grid-template-columns: 120px 140px 140px 1fr;
			grid-column-gap: 30px;
			grid-template-areas: "ac1 ac1 ac3 ac2";
		}
		li {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
			&:nth-child(1) {grid-area: ac1;}
			&:nth-child(2) {grid-area: ac2;}
			&:nth-child(3) {grid-area: ac3;}
			u {
				text-decoration: line-through;
				color: var(--grey);
			}
			small {
				color: var(--grey);
				font-size: 14px;
			}
			p {
				display: flex;
				align-items: center;
				> *:not(:last-child) {
					margin-right: 5px;
				}
			}
			a {
				color: var(--black);
				font-size: 14px;
				font-weight: 700;
				line-height: 24px;
				text-decoration: none;
				position: relative;
				&::after {
					position: absolute;
					display: block;
					width: 100%;
					height: 1px;
					content: "";
					bottom: 3px;
					left: 0;
					border-bottom: 1px dotted var(--black);
					transition: border-bottom-color .3s;
				}
				&:hover::after {
					border-bottom-color: #ff1b32;
				}
			}
		}
	}
}