.catalogFilters {
	.filterSubmit {
		top: 0;
		right: 0;
		position: sticky;
		display: block;
		width: 40px;
		height: 50px;
		outline: none;
		border: none;
		cursor: pointer;
		background-color: transparent;
		background-size: 21px 16px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIxNiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTYuNjggMTIuNjZsLTUtNUwwIDkuMzEgNi42OCAxNiAyMSAxLjY3IDE5LjMzIDB6Ii8+PC9zdmc+);
		transition: transform .3s;
		@media (width >= 1200px) {
			display: none;
		}
		&:hover {
			transform: scale(1.25);
		}
	}
}