.footerMenu {
	grid-area: fMenu;
	display: flex;
	flex-direction: column;
	width: 100%;
	@media (width >= 768px) {
		flex-direction: row;
	}
	&__section {
		border-bottom: 2px solid #E8E8E9;
		position: relative;
		@media (width >= 768px) {
			border-bottom: none;
			&:last-child {display: none;}
		}
		@media (width >= 992px) {
			&:last-child {display: block;}
		}
		@media (width >= 1200px) {
			&:not(:last-child) {
				margin-right: 5%;
			}
		}
	}
	&__listHeading {
		font-size: 16px;
		font-weight: bold;
		color: var(--black);
		text-decoration: none;
		width: 100%;
		display: flex;
		padding: 20px;
	}
	&__list {
		padding: 0 20px 20px 20px!;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@media (width >= 768px) {
			position: static!;
			margin: 0!;
			width: auto!;
			height: auto!;
			overflow: visible!;
			clip: auto!;
			padding-bottom: 0!;
		}
	}
	&__item {
		margin-bottom: 15px;
		display: flex;
		&:last-child {margin-bottom: 0;}
	}
	&__link {
		font-size: 14px;
		color: var(--black);
		text-decoration: none;
		&:hover {color: var(--blue);}
	}
	&__trigger {
		width: 22px;
		height: 22px;
		display: flex;
		border: 1px solid #95989A;
		border-radius: 50%;
		position: absolute;
		top: 19px;
		right: 20px;
		pointer-events: none;
		&::before,&::after {
			width: 11px;
			height: 2px;
			background-color: #95989A;
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
		}
		&::before {
			transform: translate(-50%, -50%);
		}
		&::after {
			transform: translate(-50%, -50%) rotate(90deg);
			transition: transform .3s;
		}
		.active + &::after {
			transform: translate(-50%, -50%) rotate(0); 
		}
		@media (width >= 768px) {
			display: none;
		}
	}
}