.modalCity {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 25px;
	&__heading {
		color: var(--black);
		font-size: 21px;
		font-weight: bold;
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
	}
	&__motto {
		display: flex;
		align-items: flex-start;
		margin-bottom: 30px;
		span {
			display: block;
			margin: 0 0 0 15px;
			color: var(--grey);
			font-size: 16px;
		}
	}
	&__popular {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
		li {
			flex-basis: 150px;
			min-width: max-content;
			margin-right: 20px;
		}
		a {
			display: flex;
			padding: 5px 10px;			
			text-decoration: none;
			font-size: 16px;
			transition: color .3s, background-color .3s;
			&:hover {
				color: var(--red);
			}
		}
		li.current {
			position: relative;
			&::after {
				content: "✓";
				position: absolute;
				top: 3px;
				right: 10px;
				color: white;
			}
		}
		li.current a {
			background-color: var(--blue);
			color: white;
		}
	}
	&__formTitle {
		display: block;
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 16px;
		color: var(--black);
	}
	&__form {
		position: relative;
		margin-bottom: 15px;
		display: flex;
		display: flex;
		align-items: center;
		border: 1px solid #E8E8E9;
		border-radius: 3px;
		transition: box-shadow .3s;
		&:focus-within {box-shadow: inset 0 0 1px 2px #E8E8E9;}
	}
	&__form input {
		width: calc(100% - 45px);
		padding: 0 0 0 10px;
		background-color: transparent;
		height: 45px;
		font-size: 13px;
		border: none;
		appearance: none;
		outline: none;
		color: var(--black);
		caret-color: var(--black);
		@media (width >= 768px) {font-size: 16px;}
		&::-webkit-input-placeholder {color: var(--grey);}
		&::-moz-placeholder {color: var(--grey);}
		&::-ms-input-placeholder {color: var(--grey);}		
	}
	&__submit {
		display: flex;
		justify-content: center;
		align-items: center;
		appearance: none;
		border: none;
		height: 100%;
		width: 45px;
		outline: none;
		background-color: transparent;
		cursor: pointer;
		path {
			@media (width >= 768px) {
				fill: var(--black);
			}
		}
	}
	&__formText {
		color: var(--grey);
		font-size: 14px;
		display: block;
		margin-top: 0;
		margin-bottom: 20px;
		a {
			text-decoration: none;
			margin-left: 5px;
			transition: color .3s;
			&:hover {color: var(--red);}
		}
	}
	&__info {
		border-top: 1px solid #EDEDEE;
		padding-top: 20px;
		color: var(--grey);
		font-size: 14px;
		margin-top: 0;
		margin-bottom: 0;
	}
}