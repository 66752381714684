.checkoutStepHeading {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 50px;
	background-color: #f3f3f3;
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 20px;
	&__number {
		color: var(--red);
		margin-right: 5px;
	}
	&__title {
		color: var(--black);
	}
}