.modalCityStart {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 25px;
	&__heading {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 20px;
		b {
			color: var(--black);
			flex-shrink: 0;
		}
		a {
			text-decoration: none;
			color: var(--blue);
			transition: color .3s;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-left: 5px;
			padding-right: 5px;
			&:hover {
				color: var(--red);
			}
		}
	}
	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		a {
			width: 120px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 30px;
			text-decoration: none;
			margin-bottom: 10px;
			transition: opacity .3s;
			font-size: 12px;
			&:hover {opacity: 0.7;}
		}
	}
	&__btnPositive {
		background-color: var(--red);
		color:  white;
		font-weight: bold;
		&:hover {color: white;}
	}
	&__btnChoose {
		color: var(--blue);
	}
}