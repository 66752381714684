.breadcrumbs {
	width: calc(100% + 20px);
	margin-left: -10px;
	margin-right: -10px;
	margin-top: 10px;
	background-color: #F3F3F3;
	padding: 10px;
	&__items {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	&__item {
		&:not(:last-child) {
			position: relative;
			margin-right: 5px;
			&::after {
				content: "/";
				padding-left: 5px;
			}
		}
	}
}