.footerCopy {
	grid-area: fCopy;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px;
	width: 100%;
	span {
		font-size: 12px;
		color: var(--black);
	}
	@media (width >= 768px) {
		padding-left: 0;
	}
}