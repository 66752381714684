.searchResult {
	width: 100%;
	background-color: white;
	border-radius: 3px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	&__heading {
		color: var(--black);
		font-size: 21px;
		font-weight: 700;
		text-align: center;
	}
	&__tagWrapper {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 20px;
		li {
			margin: 10px;
			border-radius: 3px;
			border: 1px solid #b1b5c2;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		a {
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			text-decoration: none;
			display: flex;
			padding: 10px;
			transition: color .3s;
			&:hover {
				color: var(--red);
			}
		}
	}
}