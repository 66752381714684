.itemTitle {
	font-size: 16px;
	font-weight: bold;
	color: var(--black);
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding: 30px 10px;
	text-transform: uppercase;
	text-align: center;
	@media (width >= 768px) {
		font-size: 21px;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
	}
	@media (width >= 1200px) {
		padding: 40px 0;
	}
	&__icon {
		display: flex;
		width: 36px;
		height: 36px;
		justify-content: center;
		align-items: center;
	}
	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 10px;
		text-align: left;
		@media (width >= 768px) {
			flex-direction: row;
			align-items: center;
		}
	}
	&__heading {
		@media (width >= 768px) {
			margin-right: 10px;
		}
	}
	&__subheading {
		color: #999;
	}
}