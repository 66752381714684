.linedBtn {
	 display: inline-flex;
	 height: 40px;
	 justify-content: center;
	 align-items: center;
	 border: 1px solid var(--red);
	 color: var(--red);
	 background-color: white;
	 border-radius: 3px;
	 font-size: 14px;
	 text-align: center;
	 padding-left: 60px;
	 padding-right: 60px;
	 text-decoration: none;
	 transition: background-color .3s, color .3s;
	 &:hover {
		background-color: var(--red);
	 	color: white;
	 }
}
