.indexSlider {
	@media (width >= 1200px) {
		width: calc(100% - 270px);
		margin-left: 270px;
		margin-top: -15px;
	}
	@media (width >= 1440px) {
		margin-top: 0;
	}
	&__slide {
		width: 100%;
		height: auto;
		display: flex;
		position: relative;
	}
	&__img {
		display: flex;
		picture, img {
			width: 100%;
			height: auto;
			@media (width >= 1200px) {
				height: 400px;
				object-position: center;
				object-fit: cover;
			}
		}	
	}
	&__text {
		display: none;
		@media (width >= 768px) {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: 40px;
		}
	}
	&__textHeading {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-size: 37px;
		font-weight: bold;
		color: var(--black);
		@media (width >= 992px) {font-size: 47px;}
		@media (width >= 1200px) {font-size: 42px;}
		b {color: #FFAC43;}
	}
	&__textButton {
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		font-size: 17px;
		text-transform: uppercase;
		margin-top: 35px;
		width: 210px;
		height: 50px;
		border: 1px solid #FFAC43;
		background-color: #FFAC43;
		color: white;
		transition: background-color .3s, color .3s;
		&:hover {
			background-color: white;
			color: #FFAC43;
		}
	}
}