.catalogue {
	&__item {
		position: relative;
		&--1 {--accentColor: #FF1B32;}
		&--2 {--accentColor: #FF9096;}
		&--3 {--accentColor: #AE00B1;}
		&--4 {--accentColor: #74CD46;}
		&--5 {--accentColor: #F7B700;}
		&--6 {--accentColor: #00B6FE;}
		&--7 {--accentColor: #00C995;}
		&--8 {--accentColor: #FC0059;}
		&--9 {--accentColor: #0077CB;}
		&--10 {--accentColor: #2B3C4D;}
	}
	&__itemLink {
		position: relative;
		text-decoration: none;
		color: var(--black);
		font-size: 14px;
		padding: 15px 15px 15px 80px;
		background-color: #F3F3F3;
		border-bottom: 1px solid #E0E0E0;
		display: flex;
		width: 100%;
		height: 60px;
		align-items: center;
		transition: background-color .3s;
		&:hover {background-color: white;}
		@media (width >= 1200px) {
			height: 40px;
			padding: 15px 15px 15px 55px;
			border-bottom: none;
			overflow: hidden;
			&:hover {
				background-color: var(--accentColor);
				color: white;
				&::after {
					content: "";
					position: absolute;
					display: block;
					width: 15px;
					height: 15px;
					background-color: white;
					top: 50%;
					right: 0;
					transform: translate(50%,-50%) rotate(-45deg);
				}
			}
		}
	}
	&__itemImg {
		width: 40px;
		height: 60px;
		position: absolute;
		top: 0;
		left: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		svg {
			max-height: 100%;
			width: auto;
		}
		@media (width >= 1200px) {
			top: 7px;
			width: 25px;
			height: 25px;
			path {
				fill: #A3A3A3;
			}
		}
	}
	&__itemLink:hover ~ &__itemImg {
		@media (width >= 1200px) {
			path {
				fill: white;
			}
		}
	}
	&__toggle {
		position: absolute;
		top: 15px;
		right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url(../img/arrowDown.svg);
		background-size: 12px 7px;
		background-repeat: no-repeat;
		background-position: center;
		width: 30px;
		height: 30px;
		transition: transform .3s;
		&.active  {transform: rotate(180deg);}
		@media (width >= 1200px) {
			display: none;
		}
	}
	&__subItemLink {
		display: flex;
		width: 100%;
		height: 46px;
		text-decoration: none;
		color: var(--black);
		font-size: 14px;
		padding: 15px 15px 15px 80px;
		&:hover {background-color: #fbfbfb;}
		li:not(:last-child) & {border-bottom: 1px solid #E0E0E0;}
	}
}