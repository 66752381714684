.modal {
	padding-top: 0;
	padding-bottom: 0;
	-webkit-overflow-scrolling: touch;
	&__overlay {
		position: fixed;
		padding: 25px;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-height: 100vh;
		overflow-y: auto;
		background: rgba(#fff, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
		-webkit-overflow-scrolling: touch;
		.modal--wide & {
			align-items: flex-start;
		}
		@media (height <= 767px) {
			align-items: flex-start;
		} 
	}
	&__container {
		position: relative;
		background-color: #fff;
		width: 100%;
		max-width: 600px;
		box-sizing: border-box;
		padding: 20px;
		box-shadow: 0 0 6px rgba(#000, .3);
		.modal--wide & {
			max-width: 1050px;
			padding: 60px 0 0;
		}
		@media (width >= 640px) {
			padding: 40px;
			.modal--wide & {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	&__close {
		border: 0;
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		height: 40px;
		background-color: #E8E8E9;
		transition: background-color .5s;
		padding: 0;
		cursor: pointer;
		outline: none;
		.modal--wide & {
			width: 60px;
			height: 60px;
		}
		&-cross {
			position: absolute;
			display: block;
			width: 20px;
			height: 20px;
			top: calc(50% - 10px);
			left: calc(50% - 10px);
			transform: rotate(45deg);
			transform-origin: center center;
			opacity: 0.7;
			transition: transform .5s, background-color .5s;
			pointer-events: none;
			&::before,&::after {
				display: block;
				position: absolute;
				content: "";
				background-color: var(--grey);
			}
			&::before {
				width: 20px;
				height: 2px;
				top: 9px;
				left: 0;
			}
			&::after {
				width: 2px;
				height: 20px;
				top: 0;
				left: 9px;
			}
		}
		&:hover {
			background-color: var(--blue);
		}
		&:hover &-cross {
			transform: rotate(225deg);
			&::before,&::after {
				background-color: white;
			}
		}
	}
	&__header {
		width: calc(100% - 40px);
		height: 40px;
		position: absolute;
		top: 0;
		left: 0;
		border-bottom: 1px solid #ededee;
		padding-left: 30px;
		padding-right: 30px;
		display: flex;
		align-items: center;
		.modal--wide & {
			width: calc(100% - 60px);
			height: 60px;
		}
	}
	&__heading {
		color: var(--black);
		font-size: 21px;
		font-weight: 700;
		line-height: 1;
	}
	&__content {
		width: 100%;
		min-height: 100%;
	}
}