.cardVariants {
	display: flex;
	position: relative;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 15px;
	/* z-index: 21; */
	@media (width <= 767px) {
		border-radius: 3px;
		border: 1px solid #b1b5c2;
		padding: 5px;
	}
	&__current {
		color: var(--grey);
		font-size: 14px;
		max-width: calc(100% - 25px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
		&::selection {
			background-color: transparent;
		}
		@media (width <= 767px) {
			width: calc(100% - 25px);
		}
	}
	&__trigger {
		height: 17px;
	}
	&__dropdown {
		flex-direction: column;
		align-items: flex-start;
		width: calc(100% + 20px);
		background-color: white;
		left: -10px;
		padding: 0 10px;
		top: 35px;
		border-radius: 3px;
		box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);
		@media (width >= 768px) {
			top: 25px;
			left: -20px;
			padding-left: 20px;
			padding-right: 20px;
			width: calc(100% + 40px);
		}
	}
	&__list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}
	&__item {
		display: flex;
		margin-left: -10px;
		margin-right: -10px;
		width: calc(100% + 20px);
		padding-left: 10px;
		padding-right: 10px;
		background-color: white;
		transition: background-color .3s;
		@media (width >= 768px) {
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
			padding-right: 20px;
			width: calc(100% + 40px);
		}
		&:hover {
			background-color: #f5f2f1;
		}
		&:not(:last-child) {
			@media (width <= 767px) {
				border-bottom: 1px solid #cccccc;
			}			
		}
	}
	&__link {
		display: flex;
		flex-direction: column;
		width: 100%;
		text-decoration: none;
		padding-top: 5px;
		padding-bottom: 5px;
		@media (width >= 768px) {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	}
	&__name {		
		color: var(--grey);
		font-size: 12px;
		text-align: left;		
		@media (width >= 768px) {
			flex-basis: auto;
			font-size: 13px;
		}
		@media (width >= 992px) {
			font-size: 14px;
		}
	}
	&__prices {
		font-size: 12px;
		margin-top: 5px;
		text-align: right;
		@media (width >= 768px) {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			flex-basis: 70px;
			flex-shrink: 0;
			font-size: 14px;
		}
	}
	&__price {
		font-size: 12px;
		&--regular {
			color: var(--black);
		}
		&--old {
			color: var(--grey);
			text-decoration: line-through;
		}
		&--sale {
			color: #ff0009;
		}
	}
	&__footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		@media (width >= 768px) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}
	&__more {
		text-decoration: none;
		color: #2b6bdd;
		font-size: 12px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-bottom: 5px;
	}
	&__close {
		color: #b1b5c2;
		font-size: 12px;
		font-weight: 400;	
		display: flex;
		align-items: center;
		padding: 5px;
		margin-bottom: 5px;
		cursor: pointer;
		transition: color .3s;
		&:hover {
			color: var(--black);
		}
		span {
			margin-right: 5px;
		}
		svg {
			transform: rotate(180deg);
		}
	}
}