.productTestimonials {
	margin-top: 20px;
	&__title {
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		span {
			font-size: 18px;
			font-weight: bold;
			color: var(--black);
			margin-left: 15px;
		}
	}
	&__average {
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 15px;
	}
	&__rate {
		font-size: 15px;
		font-weight: bold;
		color: var(--black);
		margin-left: 5px;
	}
	&__divider {
		display: block;
		width: 1px;
		height: 15px;
		background-color: #B1B5C2;
		margin-left: 10px;
		margin-right: 10px;
		opacity: 0;
		@media (width >= 375px) {
			opacity: 1;
		}
	}
	&__counter {
		font-size: 13px;
		color: var(--black);
	}
	&__wrapper {
		
	}
}