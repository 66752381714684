.footerTel {
	grid-area: fTel;
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #E8E8E9;
	@media (width >= 768px) {
		align-items: flex-start;
		border-bottom: none;
		padding-right: 0;
	}
	&__mobileView {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 320px;
		height: 46px;
		background-color: #75CB40;
		border-radius: 3px;
		text-decoration: none;
		transition: opacity .3s;
		&:hover {opacity: 0.7;}
		img {
			margin-right: 10px;
		}
		span {
			font-size: 18px;
			color: white;
		}
		@media (width >= 768px) {display: none;}
	}
	&__text {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
		@media (width >= 768px) {align-items: flex-start;}
		span {
			font-size: 14px;
			color: var(--black);
			text-align: center;
			@media (width >= 768px) {text-align: left;}
		}
	}
	.footerCallback {
		display: none;
		@media (width >= 768px) {
			display: flex;
			align-items: center;
		}
		&__link {font-size: 14px;}
		&__link,&__link:hover {color: var(--blue);}
	}
}