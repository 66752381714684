.checkoutStep {
	&--1 {grid-area: cStep1;}
	&--2 {grid-area: cStep2;}
	&--3 {
		grid-area: cStep3;
	}
	&--4 {
		grid-area: cStep4;
		&__wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
		}
		.modalCartItem:nth-child(1) {
			border-top: 1px solid #ededee;
		}
	}
}