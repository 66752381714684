.subscribe {
	background-color: #327CF0;
	&__wrapper {
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 768px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		@media (width >= 1200px) {
			flex-direction: row;
			justify-content: space-between;
			padding-left: 0;
			padding-right: 0;
		}
	}
	&__heading {
		font-size: 16px;
		font-weight: bold;
		color: white;
		text-align: center;
		margin-top: 0;
		margin-bottom: 20px;
		@media (width >= 768px) {
			text-align: left;
			font-size: 18px;
		}
		@media (width >= 992px) {
			font-size: 21px;
		}
		@media (width >= 1200px) {
			margin-bottom: 0;
			margin-right: auto;
		}
	}
	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		@media (width >= 768px) {
			flex-direction: row;
			justify-content: center;
		}
		@media (width >= 1200px) {
			justify-content: flex-end;
			width: auto;
		}
		input {
			appearance: none;
			outline: none!important;
			box-shadow: none;
			width: 100%;
			height: 46px;
			border-radius: 3px;
			border: 1px solid white;
			display: flex;
			padding: 12px;
			background-color: transparent;
			color: white;
			font-size: 14px;
			&:focus {outline: 1px solid white;}
			&::-webkit-input-placeholder {color: white;}
			&::-moz-placeholder {color: white;}
			&::-ms-input-placeholder {color: white;}
		}
		[type="submit"] {
			text-align: center;
			justify-content: center;
			color: #327CF0;
			font-weight: bold;
			background-color: white;
			font-size: 18px;
			transition: opacity .3s;
			cursor: pointer;
			&:hover {opacity: 0.8;}
		}
	}
	.formgroup {
		position: relative;
		width: 100%;
		max-width: 320px;
		min-width: 250px;
		&:not(:last-child) {margin-bottom: 15px;}
		@media (width >= 768px) {
			&:not(:last-child) {
				margin-bottom: 0;
				padding-right: 10px;
			}
		}
	}
}