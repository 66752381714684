.viewList .catalogContent{
	&__itemsWrapper {
		grid-template-columns: 1fr;
	}
	.itemCard {
		grid-column: 1 / -1;
		display: grid;
		grid-template-columns: 100px 1fr;
		grid-template-rows: max-content auto;
		grid-template-areas:
		"lImg lContent"
		"lTop lContent";
		align-items: start;
		grid-gap: 10px;
		background-color: white;
		@media (width >= 375px) {
			grid-template-columns: 150px 1fr;
		}
		@media (width >= 480px) {
			grid-template-columns: 250px 1fr;
		}
		@media (width >= 768px) {
			grid-template-columns: 250px 1fr 250px;
			grid-template-rows: auto;
			grid-template-areas:
			"lImg lContent lTop"
		}
		@media (width >= 992px) {
			grid-template-columns: 310px 1fr 250px;
		}
		&__top {
			grid-area: lTop;
			display: flex;
			opacity: 1;
			flex-direction: column;
			align-items: flex-start;
			position: static;
			margin-bottom: 10px;
			@media (width >= 480px) {
				flex-direction: row;
				align-items: center;
			}
			@media (width >= 768px) {
				flex-direction: column;
				align-items: flex-end;
				padding-right: 10px;
			}
			@media (width >= 992px) {
				flex-direction: row;
				align-items: center;
				padding-top: 10px;
			}
		}
		&__addToFavouriteText {
			@media (width >= 480px) {
				display: block;
				color: var(--grey);
				font-size: 14px;
				font-weight: 500;
				line-height: 1;
				margin-left: 5px;
				border-bottom: 1px solid currentColor;
				transition: color .3s;
			}
		}
		&__addToFavourite:hover span {
			color: white;
		}
		&__id {
			margin-left: 5px;
		}
		&__addToFavourite {
			margin-left: 5px;
			margin-right: 0;
		}
		&__img {
			grid-area: lImg;
			padding-top: 10px;
			padding-left: 10px;
			background-color: white;
			@media (width >= 768px) {
				padding-bottom: 10px;
			}
		}
		&__content {
			grid-area: lContent;
			height: 100%;
		}
		&__testimonialsHolder {
			justify-content: flex-start;
		}
		&__testimonialsRate {
			margin-left: 10px;
		}
		&__labelHolder {
			right: auto;
			left: 0;
			@media (width >= 768px) {
				position: absolute;
				justify-content: flex-start;
			}
		}
		&__title {
			order: -1;
		}
		&__buyBtnHolder {
			@media (width >= 768px) {
				display: flex;
				position: static;
				padding: 0;
				box-shadow: none;
				margin-top: auto;
			}
		}
		&__buyBtn {
			width: auto;
			min-width: 140px;
		}
	}
}