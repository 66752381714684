.similarItems {
	margin-bottom: 70px;
	&__wrapper {
		display: grid;
		grid-gap: 2px;
		grid-template-columns: repeat(2, calc(50% - 1px));
		@media (width >= 768px) {grid-template-columns: repeat(3, calc(33.33% - 1px));}
		@media (width >= 1200px) {grid-template-columns: repeat(4, calc(25% - 1px));}
	}
	.itemCard {
		&:nth-child(n+3) {display: none;}
		@media (width >= 768px) {
			&:nth-child(n+3) {display: flex;}
			&:nth-child(n+4) {display: none;}
		}
		@media (width >= 1200px) {
			&:nth-child(n+4) {display: flex;}
			&:nth-child(n+5) {display: none;}
		}
	}
	&__linkWrapper {
		width: 100%;
		padding: 30px;
		border-radius: 3px;
		background-color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 3px;
	}
	&__link {
		color: #ff1b32;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 280px;
		max-width: 100%;
		height: 40px;
		border-radius: 3px;
		border: 1px solid #ff1b32;
		text-decoration: none;
		transition: background-color .3s, color .3s;
		&:hover {
			background-color: #ff1b32;
			color: white;
		}
	}
}