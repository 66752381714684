.headerTels {
	display: none;
	position: relative;
	@media (width >= 768px) {
		display: flex;
		align-items: center;
		width: 56px;
		height: 56px;
		justify-content: center;
		align-items: center;
		background-color: #E8E8E9;
		border-radius: 50%;
	}
	@media (width >= 992px) {
		min-width: 150px;
		width: auto;
		height: auto;
		border-radius: 0;
		background-color: transparent;
		justify-content: flex-start;
		margin-left: 0;
		margin-right: 20px;
	}
	& > &__icon {
		@media (width >= 992px) {
			margin-bottom: 3px;
			margin-right: 12px;
		}
	}
	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		width: 20px;
		@media (width >= 992px) {margin-right: 10px;}
		> & svg {
			width: 26px;
			height: 26px;
			@media (width >= 992px) {
				width: auto;
				height: auto;
			}
			path {
				transition: fill .3s;
				@media (width <= 991px) {.headerTels:hover & {fill: var(--black);}}				
			}
		}
	}
	.js-dropdownFirstTrigger {
		position: absolute;
		width: 100%;
		height: 100%;
		@media (width >= 992px) {
			width: auto;
			right: 0;
		}
		svg {
			display: none;
			@media (width >= 992px) {display: block;}
		}		
	}
	&__current {
		display: none;
		@media (width >= 992px) {
			display: block;
			font-size: 14px;
			font-weight: bold;
			color: var(--black);
			position: relative;
			z-index: 2;
			.js-active & {display: none;}
		}
	}
	&__dropdown {
		top: 3px;
		left: 3px;
		min-width: 170px;
		background-color: white;
		border-radius: 3px;
		box-shadow: 0 0 6px rgba(#000, .16);
		width: calc(100% + 30px);
		padding: 15px;
		@media (width >= 992px) {
					left: -15px;
					top: -15px;
		}
		ul {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;			
			&:not(:last-child) {margin-bottom: 10px;}
		}
		li > span, li > a {
			margin-left: auto;
			flex-basis: calc(100% - 30px);
		}
		li > a {
			text-decoration: none;
			color: var(--black);
			font-size: 16px;
			font-weight: bold;
			transition: color .3s;
			&:hover {color: var(--blue);}
		}
		li > span {
			display: flex;
			flex-direction: column;
			color: #6D6D6D;
			font-size: 11px;
		}
	}
}