.itemMainLabels {
	display: flex;
	width: 100%;
	height: 50px;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	z-index: 1;
	pointer-events: none;
	&__holder {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		position: absolute;
		top: 0;
		right: 0;
	}
	&__label {
		color: white;
		font-size: 10px;
		font-weight: 700;
		display: flex;
		padding: 5px 10px;
		text-transform: uppercase;
		background-color: var(--itemCardLabelBg);
		&--sales {--itemCardLabelBg: #ff0009;}
		&--discount {--itemCardLabelBg: #e8b734;}
		&--new {--itemCardLabelBg: #75cb40;}
		&--top {--itemCardLabelBg: #8921b7;}
		&:not(:last-child) {
			margin-bottom: 2px;
		}
	}
}