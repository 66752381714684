.mMenuFirst {
	li {
		position: relative;
	}
	a {
		position: relative;
		text-decoration: none;
		color: var(--black);
		font-size: 14px;
		padding: 15px 15px 15px 55px;
		background-color: #F3F3F3;
		border-bottom: 1px solid #E0E0E0;
		display: flex;
		width: 100%;
		height: 50px;
		align-items: center;
		transition: background-color .3s;
		&:hover {background-color: white;}
	}
	.img {
		width: 40px;
		height: 50px;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}