.mMenuAccount {
	padding: 20px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	a {
		color: var(--black);
		font-weight: bold;
		font-size: 14px;
		text-decoration: none;
		transition: color .3s;
		&:hover {color: var(--blue);}
	}
	span {
		display: block;
		width: 1px;
		height: 30px;
		background-color: #F3F3F3;
		margin-left: 10px;
		margin-right: 10px;
	}
}