.headerBrandsDropdown {
	@media (width <= 1199px) {
		&.js-dropdownContent, {
			display: none !important;
		}
	}
	.header-is-fixed &.js-dropdownContent {display: none !important;}
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
	top: 27px;
	left: -10px;
	&__list {
		display: flex;
		flex-direction: column;
		padding: 10px;
		background-color: #FFF;
		border-radius: 3px;
		box-shadow: 0 0 6px rgba(#000, .16);
		min-width: 150px;
	}
	&__item {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	&__link {
		font-size: 14px;
		text-decoration: none;
		color: var(--black);
		transition: color .3s;
		&:hover {color: var(--blue);}
	}
	&__item:last-child a {color: var(--blue);}
	&__item:last-child a:hover {color: var(--red);}
}