.mMenuCat {
	display: none;
	.js-overlay--subBox & {z-index: 30;}
	.menuCat-is-open & {top: 157px;}
	.header-is-fixed.menuCat-is-open & {top: 50px;}
	@media (width >= 768px) {
		/* .menuCat-is-open & {top: 177px;} */
		.header-is-fixed.menuCat-is-open & {top: 50px;}
	}
	@media (width >= 992px) {
		.menuCat-is-open & {top: 159px;}
	}
	@media (width >= 1200px) {
		.menuCat-is-open & {
			animation: mMenuShadow .3s .3s forwards;
		}
		.page--index &,
		.menuCat-is-open & {
			display: block;
			position: absolute;
			left: calc( (100% - var(--containerWidth) ) / 2 );
			top: 174px;
			animation: mMenuShadow .3s .3s forwards;
			z-index: 30;
			.menuCat-is-open & {top: 174px;}
		}
		.header-is-fixed & {
			top: 60px;
			display: none;
		}
		.header-is-fixed.menuCat-is-open & {
			top: 71px;
			display: block;
		}
	}
	@media (width >= 1440px) {
		.header-is-fixed.menuCat-is-open & {
			top: 61px;
		}
	}
	@media (width <= 1199px) {
		.menuCat-is-open & {
			display: block;
			position: absolute;
			left: 0;
			z-index: 30;
			/* max-height: 100vh; */
			overflow-y: auto;
			animation: mMenuShadow .3s .3s forwards;
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		width: 100vw;
		max-width: 320px;
		background-color: #FFF;		
		transform: translateX(-100%);
		animation: mMenuReveal .3s forwards;
		@media (width >= 1200px) {
			transform: translateX(0);
			animation-duration: 0s!;
			width: 270px;
		}
	}
}
@keyframes mMenuReveal {
	from {transform: translateX(-100%);}
	to {transform: translateX(0);}
}

@keyframes mMenuShadow {
	from {box-shadow: 0 0 0 rgba(#000, .16);}
	to {box-shadow: 0 0 6px rgba(#000, .16);}
}