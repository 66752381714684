.catalogSelectedOptions {
	padding: 20px 10px;
	background-color: white;
	border-radius: 3px;
	margin-top: 2px;
	margin-bottom: 2px;
	display: none;
	@media (width >= 1200px) {
		margin-top: 0;
	}
	@media (width >= 1440px) {
		margin-bottom: 20px;
	}
	&.is-active {
		display: block;
	}
	&__heading {
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 15px;
	}
	&__title {
		color: var(--black);
		font-size: 14px;
		font-weight: 700;
	}
	&__clearAll {
		display: flex;
		align-items: center;
		margin-left: auto;
		text-decoration: none;
		span {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			line-height: normal;
		}
	}
	&__content {
		
	}
	&__criterion {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	&__criterionName {
		color: var(--black);
		font-size: 14px;
		font-weight: 400;
		line-height: normal;
		margin-right: 10px;
	}
	&__criterionList {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		li {
			margin: 5px 10px 5px 0;
		}
		a {
			display: flex;
			width: 100%;
			align-items: center;
			border-radius: 10px;
			padding: 5px;
			border: 1px solid #95989a;
			text-decoration: none;
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			line-height: normal;
			transition: color .3s, border-color .3s;
			&:hover {
				color: var(--red);
				border-color: var(--red);
			}
			span {
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	}
	.clearIcon {
		display: flex;
		width: 21px;
		height: 21px;
		background-size: 9px 9px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCA5IDkiPiAgPHBhdGggZmlsbD0iI2ZmMDAwOSIgZD0iTTUuMyA0LjVMOC45NC44NmEuMTkuMTkgMCAwIDAgMC0uMjhMOC40Mi4wNmEuMTkuMTkgMCAwIDAtLjI4IDBMNC41IDMuNy44Ny4wNmEuMjEuMjEgMCAwIDAtLjI5IDBMLjA2LjU4YS4xOS4xOSAwIDAgMCAwIC4yOEwzLjcgNC41LjA2IDguMTRhLjE5LjE5IDAgMCAwIDAgLjI4bC41Mi41MmEuMTkuMTkgMCAwIDAgLjI4IDBMNC41IDUuM2wzLjY0IDMuNjRhLjE5LjE5IDAgMCAwIC4yOCAwbC41Mi0uNTJhLjE5LjE5IDAgMCAwIDAtLjI4eiIvPjwvc3ZnPg==);
		filter: grayscale(1);
		transition: filter .3s;
	}
	a:hover > .clearIcon {
		filter: grayscale(0);
	}
}
