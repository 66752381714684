.itemMainSimilar {
	display: flex;
	position: relative;
	padding-top: 0;
	padding-bottom: 15px;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	
	&__button {
		width: 100%;
		max-width: 320px;
		height: 40px;
		background-color: white;
		border: 1px solid #EEEEEE;
		color: #333333;
		font-size: 18px;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 10px;
		border-radius: 3px;
		text-decoration: none;
		transition: background-color .3s, color .3s;
		@media (width >= 768px) {
			height: 55px;
		}
		&:hover {
			background-color: #EEEEEE;
		}
	}
}