.breadcrumbs {
	width: calc(100% + 20px);
	margin-left: -10px;
	margin-right: -10px;
	margin-top: 10px;
	margin-bottom: -10px;
	background-color: #F3F3F3;
	padding: 10px;
	@media (width >= 768px) {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		margin-top: 0;
		margin-bottom: -20px;
		padding: 15px 20px;
		background-color: white;
	}
	@media (width >= 992px) {
		margin-bottom: -10px;
	}
	@media (width >= 1200px) {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: -15px;
	}
	@media (width >= 1440px) {
		margin-bottom: 0;
	}
	.header-is-fixed & {display: none;}
	&__items {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
	}
	&__item {
		&:not(:last-child) {
			position: relative;
			margin-right: 5px;
			font-size: 14px;
			display: flex;
			align-items: baseline;
			&::after {
				content: "/";
				padding-left: 5px;
			}
		}
		a {
			color: var(--blue);
			font-size: 14px;
			text-decoration: none;
			line-height: normal;
			transition: opacity .3s;
			&:hover {opacity: 0.7;}
		}
		span {
			font-size: 14px;
			line-height: normal;
		}
	}
}