.stickyBar {
	display: none;
	@media (width >= 1440px) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 50px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		background-color: white;
		padding-top: 7px;
		padding-bottom: 7px;
		position: sticky;
		top: 105px;
		grid-column: 1/2;
		grid-row:1/2;
		margin-top: 25px;
		margin-bottom: 25px;
	}
	&__item {
		width: 100%;
		height: 40px;
	}
	&__item a {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		position: relative;
		transition: background-color .3s;
	}
	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			max-height: 30px;
			max-width: 30px;
		}
		path {
			transition: fill .3s;
			fill: #A3A3A3;
		}
	}
	&__item a.active,
	&__item:hover a {
		background-color: var(--catDirectionColor);
	}
	&__item a.active path,
	&__item:hover path {
		fill: white;
	}
	&__item:nth-child(1) {--catDirectionColor: #ff9096;}
	&__item:nth-child(2) {--catDirectionColor: #ae00b1;}
	&__item:nth-child(3) {--catDirectionColor: #74cd46;}
	&__item:nth-child(4) {--catDirectionColor: #f7b700;}
	&__item:nth-child(5) {--catDirectionColor: #00b6fe;}
	&__item:nth-child(6) {--catDirectionColor: #00c995;}
	&__item:nth-child(7) {--catDirectionColor: #fc0059;}
	&__item:nth-child(8) {--catDirectionColor: #0077cb;}
	&__item:nth-child(9) {--catDirectionColor: #2b3c4d;}
	&__title {
		display: flex;
		position: absolute;
		height: 100%;
		width: 225px;
		top: 0;
		left: 100%;
		align-items: center;
		padding-left: 15px;
		padding-right: 15px;
		color: white;
		background-color: var(--catDirectionColor);
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s;
	}
	&__item a:hover &__title {
		visibility: visible;
		opacity: 1;
	}
	&__item a:focus:not(:hover) &__title {
		visibility: hidden;
		opacity: 0;
	}
}