.catalogFilters {
	.criterionFilter__search {
		border-radius: 3px;
		border: 1px solid #e8e8e9;
		background-color: transparent;
		appearance: none;
		font-size: 14px;
		font-weight: 400;
		padding: 5px 10px;
		caret-color: var(--black);
		color: var(--black);
		margin-bottom: 20px;
		&::placeholder {
			color: #a7a7a7;
		}
	}
	.criterionFilter__list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-right: 20px;
		max-height: 198px;
		overflow-y: auto;
		&::-webkit-scrollbar {width: 8px;}
		&::-webkit-scrollbar-track {background-color: #e5e2e1;}
		&::-webkit-scrollbar-thumb {background-color: #a7a7a7;}
		label {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			line-height: 2;
			padding-left: 35px;
			position: relative;
			min-height: 20px;
			display: flex;
			cursor: pointer;
			&::selection {
				background-color: transparent;
			}
		}
		.criterionFilter__checkbox {
			position: absolute;
			opacity: 0;
			z-index: -1;
			cursor: pointer;
		}
		.criterionFilter__square {
			position: absolute;
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 3px;
			border: 1px solid #e8e8e9;
			top: 3px;
			left: 0;
		}
		.criterionFilter__checkbox:checked + .criterionFilter__square::after {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			background-color: #dedede;
			border-radius: 3px;
			color: white;
			top: -1px;
			left: -1px;
			content: "✔";
		}
	}
}