.backToTop {
	display: flex;
	width: 44px;
	height: 44px;
	justify-content: center;
	align-items: center;
	background-color: #FF1B32;
	position: fixed;
	right: 10px;
	bottom: 90px;
	z-index: 100;
	cursor: pointer;
	transition: opacity .3s;
	&:hover {opacity: 0.7;}
	img {
		width: 20px;
		height: auto;
	}
}