.stars {
	display: flex;
	align-items: center;
	i {
		display: flex;
		background-image: url(../img/fave.svg);
		background-repeat: no-repeat;
		width: 17px;
		height: 20px;
		background-size: 100%;
	}
	&.rate1 i:nth-child(n+2) {filter:grayscale(1);}
	&.rate2 i:nth-child(n+3) {filter:grayscale(1);}
	&.rate3 i:nth-child(n+4) {filter:grayscale(1);}
	&.rate4 i:nth-child(n+5) {filter:grayscale(1);}
}