.checkoutMain {
	display: flex;
	width: 100%;
	background-color: #FFF;
	padding-top: 30px;
	padding-bottom: 30px;
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
		"cTabs cTabs cTabs"
		"cStep1 cStep2 cStep3"
		". cStep4 cStep4"
		". cConfirm cConfirm";
		grid-gap: 30px;
		@media (width <= 1199px) {
			padding-left: 15px;
			padding-right: 15px;
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
			"cTabs cTabs"
			"cStep1 ."
			"cStep2 cStep3"
			"cStep4 cStep4"
			"cConfirm cConfirm";
		}
		@media (width <= 767px) {
			grid-template-columns: 1fr minmax(290px, 500px) 1fr;
			grid-template-areas:
			". cTabs ."
			". cStep1 ."
			". cStep2 ."
			". cStep3 ."
			". cStep4 ."
			". cConfirm .";
		}
		@media (width <= 479px) {
			grid-template-columns: 1fr;
			grid-template-areas:
			"cTabs"
			"cStep1"
			"cStep2"
			"cStep3"
			"cStep4"
			"cConfirm";
		}
	}
}