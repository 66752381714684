.accountMain--personal-information {
	.personalInformation__heading {
		padding: 25px 35px;
		background-color: white;
		@media (width >= 992px) {
			padding-left: 40px;
			padding-right: 40px;
		}
		h2 {
			color: var(--black);
			font-family: Helvetica;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			@media (width >= 768px) {
				font-size: 24px;
			}
			@media (width >= 992px) {
				text-align: left;
			}
		}
	}
	.personalInformation__bonuses {
		padding: 25px 35px;
		background-color: #f7f7eb;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		@media (width >= 992px) {
			padding-left: 40px;
			padding-right: 40px;
			flex-direction: row;
		}
		.bonusesImg {
			margin-bottom: 10px;
			@media (width >= 992px) {
				margin-bottom: 0;
				margin-left: -30px;
			}
		}
		.availableBonuses p {
			color: var(--black);
			font-family: Helvetica;
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 20px;
			@media (width >= 992px) {
				margin-bottom: 0;
				margin-left: 25px;
			}
			b {
				color: #00a705;
				font-size: 24px;
				font-weight: 400;
			}
		}
		.bonusesInfo {
			display: flex;
			align-items: center;
			cursor: pointer;
			position: relative;
			@media (width >= 992px) {
				margin-left: auto;
			}
			.trigger {
				width: 40px;
				height: 40px;
				border: 1px solid var(--black);
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				font-weight: bold;
				flex-shrink: 0;
			}
		}
		.text {
			margin-left: 10px;
			p {
				color: var(--black);
				font-size: 14px;
				font-weight: 400;
				margin-top: 0;
				margin-bottom: 0;
			}
		}
		.fullText {
			border: 1px solid #e8e8e9;
			border-radius: 10px;
			background-color: white;
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			top: 50px;
			left: 0;
			&::before {
				position: absolute;
				top: -11px;
				left: 53px;
				border-top: 1px solid #e8e8e9;
				border-left: 1px solid #e8e8e9;
				background-color: white;
				width: 20px;
				height: 20px;
				content: "";
				transform: rotate(45deg);
			}
			@media (width >= 640px) {
				width: 370px;
			}
			p {
				color: var(--black);
				font-size: 13px;
				font-weight: 400;
				margin-top: 0;
				margin-bottom: 0;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}
		}
	}
	.personalInformation__data {
		padding: 25px 35px;
		background-color: white;
		@media (width >= 992px) {
			padding: 50px 40px;
		}
		h2 {
			color: var(--black);
			font-size: 21px;
			font-weight: 700;
			margin-top: 0;
			margin-bottom: 25px;
		}
		dl {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-top: 0;
			margin-bottom: 30px;
			@media (width >= 768px) {
				display: grid;
				grid-template-columns: auto 1fr;
				grid-column-gap: 40px;
			}
		}
		dt,dd {
			color: var(--black);
			font-size: 16px;
			line-height: 2;
		}
		dt {
			font-weight: 700;
		}
		dd {
			font-weight: 400;
			margin-left: 0;
		}
		.link {
			display: flex;
			text-decoration: none;
			align-items: center;
			justify-content: center;
			padding: 10px 25px;
			width: 100%;
			max-width: 280px;
			margin-left: auto;
			margin-right: auto;
			color: #ff1b32;
			font-size: 14px;
			font-weight: 700;
			border-radius: 30px;
			border: 1px solid #ff1b32;
			transition: color .3s, background-color .3s;
			@media (width >= 480px) {
				margin-left: 0;
				width: auto;
			}
			&:hover {
				color: white;
				background-color: #ff1b32;
			}
		}
	}
}