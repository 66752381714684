.togglerShowTrigger {
	cursor: pointer;
	position: relative;
	margin-bottom: 0;
	&::after {
		display: block;
		position: absolute;
		content: "";
		width: 14px;
		height: 8px;
		background-size: 14px 8px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(../img/account/accordion-title-trigger.svg);
		transition: transform .3s;
		pointer-events: none;
		top: 7px;
		right: 0;
	}
	&.is-show {
		margin-bottom: 20px;
		&::after {
			transform: rotateX(180deg);
		}
	}
}

.togglerShowContent {
	height: 0;
	overflow: hidden;
	.is-show + & {
		height: auto;
	}
}