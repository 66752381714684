.itemMainSales {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	@media (width >= 768px) {
		max-width: 160px;
		margin-left: auto;
	}
	@media (width >= 1200px) {
		margin-left: 0;
	}
	&__heading {
		color: var(--grey);
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		text-align: center;
		margin-bottom: 10px;
	}
	&__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 280px;
		height: 60px;
		border-radius: 3px;
		background-color: #f5f2f1;
	}
	&__section {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		color: var(--black);
		&:not(:last-child) {
			position: relative;
			&::after {
				display: block;
				content: "";
				position: absolute;
				width: 2px;
				height: 60px;
				top: 0;
				right: 0;
				background-image: linear-gradient(180deg, #f5f2f1 0%, white 54%, #f5f2f1 100%);
			}
		}
		b {
			font-size: 21px;
			font-weight: 700;
			line-height: 21px;
		}
		span {
			font-size: 10px;
			font-weight: 400;
		}
		&--accent {
			color: var(--red);
		}
	}
}