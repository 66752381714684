.burger {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	outline: none;
	appearance: none;
	position: relative;
	cursor: pointer;
	span {
		display: block;
		background-color: var(--burgerBG);
		width: 28px;
		height: 2px;
		border-radius: 5px;
		will-change: transform;
		transition: transform .3s;
		transform-origin: center center;
		transform: translate(0,0) rotate(0);
	}
	span + span {margin-top: 6px;}
}

.js-triggered.burger span:nth-child(1),
.js-triggered .burger span:nth-child(1) {transform: translateY(8px) rotate(45deg);}

.js-triggered.burger span:nth-child(2),
.js-triggered .burger span:nth-child(2) {transform: rotateY(90deg);}

.js-triggered.burger span:nth-child(3),
.js-triggered .burger span:nth-child(3) {transform: translateY(-8px) rotate(-45deg);}