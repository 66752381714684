.catalogSorting {
	grid-area: flSorting;
	width: 100%;
	background-color: white;
	padding: 0 10px 10px;
	display: flex;
	justify-content: center;
	position: relative;
	@media (width >= 768px) {
		padding-top: 10px;
	}
	@media (width >= 1200px) {
		background-color: #F3F3F3;
		justify-content: flex-start;
	}
	&__current {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 0;
		@media (width >= 1200px) {
			padding-left: 30px;
		}
		span {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			@media (width >= 768px) {
				font-size: 17px;
				font-weight: 500;
			}
			&::after {
				content: attr(data-filter-criterion);
			}
		}
		svg {
			margin-left: 10px;
			margin-top: 3px;
		}
	}
	&__dropdown {
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
		border-radius: 3px;
		background-color: #ffffff;
		padding: 10px 30px;
		top: -10px;
		min-width: 220px;
		justify-content: center;
		@media (width >= 768px) {
			top: 5px;
			min-width: 300px;
		}
		@media (width >= 1200px) {
			justify-content: flex-start;
		}
		ul {
			padding-top: 19px;
		}
		li {
			text-align: center;
			@media (width >= 1200px) {
				text-align: left;
			}
			&.js-active {display: none;}
		}
		a {
			color: var(--black);
			font-size: 14px;
			line-height: 1.5;
			font-weight: 400;
			text-decoration: none;
			transition: color .3s;
			@media (width >= 768px) {
				font-size: 17px;
				font-weight: 500;
			}
			&::after {
				content: attr(data-filter-criterion);
			}
			&:hover {
				color: #ff0009;
			}
		}
	}
}