.accountMain--wish-list {
	padding-top: 25px;
	background-color: white;
	@media (width >= 1200px) {
		padding-top: 30px;
	}
	h2 {
		padding-left: 20px;
		padding-right: 20px;
		color: var(--black);
		font-size: 16px;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 25px;
		@media (width >= 768px) {
			font-size: 24px;
		}
		@media (width >= 992px) {
			text-align: left;
		}
		@media (width >= 1200px) {
			padding-left: 40px;
			padding-right: 40px;
			margin-bottom: 30px;
		}
	}
	.wish-list__footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 25px;
		@media (width >= 768px) {
			flex-direction: row;
			justify-content: center;
		}
		p {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
		}
		b {
			font-size: 19px;
			font-weight: 500;
			margin-left: 10px;
		}
		.button {
			width: 100%;
			max-width: 280px;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			border-radius: 3px;
			background-color: #ff1b32;
			border: 1px solid #ff1b32;
			color: white;
			font-size: 18px;
			font-weight: 700;
			transition: color .3s, background-color .3s;
			margin-top: 20px;
			text-decoration: none;
			@media (width >= 768px) {
				margin-top: 0;
				margin-left: 20px;
			}
			&:hover {
				color: #ff1b32;
				background-color: white;
			}
		}
	}
}

.account-item-grid {
	border-top: 2px solid #F3F3F3;
	border-bottom: 2px solid #F3F3F3;
	background-color: #F3F3F3;
	width: 100%;
	display: grid;
	grid-gap: 2px;
	grid-template-columns: repeat(2, calc(50% - 1px));
	@media (width >= 768px) {
		grid-template-columns: repeat(3, calc(33.33% - 1px));
	}
}