.accountMain--your-testimonials {
	.your-testimonials__heading {
		padding: 20px;
		background-color: white;
		@media (width >= 768px) {
			padding: 25px 40px;
		}
		h2 {
			color: var(--black);
			font-size: 16px;
			font-weight: 700;
			margin-top: 20px;
			margin-bottom: 20px;
			@media (width >= 768px) {
				font-size: 24px;
			}
			@media (width >= 992px) {
				text-align: left;
			}
		}
	}
	.accordion-title {
		padding: 20px 20px 0;
		background-color: white;
		display: flex;
		align-items: center;
		flex-direction: column;
		border: 1px solid #e6e6db;
		background-color: #f7f7eb;
		@media (width >= 768px) {
			padding: 25px 40px 0;
		}
		@media (width >= 992px) {
			flex-direction: row;
			padding-bottom: 25px;
		}
		ul {
			width: 100%;
			margin-top: 0;
			margin-bottom: 0;
			padding-left: 0;
			list-style: none;
			display: grid;
			grid-template-columns: 70px 1fr;
			grid-template-areas:
			"t1 t2"
			"t1 t3";
			grid-gap: 10px 15px;
			@media (width >= 768px) {
				align-items: center;
				grid-template-columns: 70px auto 1fr;
				grid-template-areas:
				"t1 t2 t3";
				grid-column-gap: 40px;
			}
			li {
				display: flex;
				width: 100%;
				flex-direction: column;
				align-items: flex-start;
				&:nth-child(1) {grid-area: t1;}
				&:nth-child(2) {grid-area: t2;}
				&:nth-child(3) {grid-area: t3;}
			}
			a {
				text-decoration: none;
				transition: opacity .3s;
				&:hover {
					opacity: 0.7;
				}
			}
			small {
				color: var(--grey);
				font-size: 14px;
				line-height: 1.5;
				font-weight: 400;
			}
			p {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				align-items: flex-start;
				color: var(--black);
				font-size: 14px;
				line-height: 1.5;
				font-weight: 400;
				> *:not(:last-child) {
					margin-right: 5px;
				}
			}
			u {
				text-decoration: line-through;
				color: var(--grey);
			}
			mark {
				background: none;
				color: #ff1b32;
			}
		}
		.accordion-title__trigger {
			width: 100%;
			height: 44px;
			background-size: 14px 8px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(../img/account/accordion-title-trigger.svg);
			transition: transform .3s;
			margin-left: auto;
			margin-right: auto;
			cursor: pointer;
			@media (width >= 992px) {
				width: 44px;
			}
		}
		&.is-open .accordion-title__trigger {
			transform: rotateX(180deg);
		}
	}
	.accordion-content {
		background-color: white;
		padding-left: 20px;
		padding-right: 20px;
		overflow: hidden;
		opacity: 0;
		max-height: 0;
		transition: opacity .3s;
		@media (width >= 768px) {
			padding-left: 40px;
			padding-right: 40px;
		}
	}
	.is-open + .accordion-content {
		opacity: 1;
		max-height: none;
		padding-top: 20px;
		padding-bottom: 20px;
		@media (width >= 768px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
	.accordion-content__item {

	}
}