.pagination {
	padding: 30px 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	&__list {
		display: flex;
		margin-bottom: 15px;
		li {
			width: 34px;
			height: 34px;
			border-radius: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #B1B5C2;
			transition: opacity .3s;
			@media (width >= 640px) {
				width: 44px;
				height: 44px;
			}
			&:hover {
				opacity: 0.7;
			}
			a {
				color: var(--black);
				text-decoration: none;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			
			&:first-child, &:last-child {
				background-color: var(--red);
				border: 1px solid var(--red);
			}
			&:not(:last-child) {
				margin-right: 5px;
			}
			&.disabled {
				filter: grayscale(1);
				opacity: 0.5;
				a {
					cursor: not-allowed;
				}				
			}
			&.active {
				a {
					color: var(--red);
				}
			}
			&.paginationFill {
				border-color: transparent;
				pointer-events: none;
			}
		}
	}
	&__nextBtn {
		min-width: 200px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		text-decoration: none;
		color: var(--red);
		background-color: transparent;
		border: 1px solid var(--red);
		transition: color .3s, background-color .3s;
		&:hover {
			background-color: var(--red);
			color: var(--bodyBG);
		}
	}
}