.modalCartItem {
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid #ededee;
	&__wrapper {
		width: 100%;
		max-width: 850px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (width <= 767px) {
			flex-wrap: wrap;
		}
	}
	&__left {
		display: flex;
		align-items: center;
		text-decoration: none;
		transition: opacity .3s;
		@media (width <= 991px) {
			flex-basis: 50%;
		}
		@media (width <= 767px) {
			flex-basis: 100%;
			padding-bottom: 15px;
		}
		@media (width <= 479px) {
			align-items: flex-start;
		}
		&:hover {
			opacity: 0.7;
		}
	}
	&__img {
		width: 85px;
		height: 85px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 40px;
		@media (width <= 479px) {
			margin-right: 20px;
			margin-top: 5px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	&__description {
		display: flex;
		width: 100%;
		max-width: 350px;
		flex-direction: column;
		align-items: flex-start;
	}
	&__heading {
		color: var(--black);
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
		margin-bottom: 5px;
	}
	&__subheading {
		color: var(--grey);
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;
	}
	&__right {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 320px;
		@media (width <= 991px) {
			flex-basis: 50%;
		}
		@media (width <= 767px) {
			flex-basis: 100%;
			max-width: none;
			justify-content: flex-end;
		}
	}
	&__counter {
		display: flex;
		height: 40px;
		border-radius: 3px;
		border: 2px solid #b1b5c2;
		background-color: white;
		button {
			width: 30px;
			height: 100%;
			border: none;
			outline: none;
			appearance: none;
			color: #b1b5c2;
			font-size: 16px;
			font-weight: 700;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;
			transition: color .3s, background-color .3s;
			cursor: pointer;
			&:hover {
				color: white;
				background-color: #b1b5c2;
			}
		}
		input {
			width: 40px;
			border: none;
			outline: none;
			appearance: none;
			color: var(--black);
			font-size: 16px;
			font-weight: 700;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}
	&__price {
		color: var(--black);
		font-size: 19px;
		font-weight: 400;
		line-height: 1;
		margin-left: 15px;
		margin-right: 15px;
		@media (width <= 479px) {
			font-size: 16px;
			margin-left: 10px;
			margin-right: 10px;
		}
	}
	&__delete {
		width: 40px;
		height: 40px;
		border: none;
		outline: none;
		appearance: none;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FF0009' d='M5.9 5l4-4a.22.22 0 0 0 0-.31L9.36.07a.22.22 0 0 0-.31 0H9l-4 4-4-4a.22.22 0 0 0-.32 0L.07.64A.22.22 0 0 0 .06 1l4 4-4 4a.22.22 0 0 0 0 .31l.57.57a.22.22 0 0 0 .31 0H1l4-4 4 4a.22.22 0 0 0 .31 0l.57-.57a.22.22 0 0 0 0-.31z'/%3E%3C/svg%3E");
		filter: grayscale(1);
		transition: filter .3s;
		cursor: pointer;
		&:hover {
			filter: grayscale(0);
		}
	}
}