.checkoutMain .checkoutStep {
	.checkout-bonus {
		margin-top: 20px;
		width: 100%;
		.ac {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		&__title {
			color: var(--black);
			font-size: 18px;
			font-weight: 700;
			width: 100%;
		}
		&__form {
			padding: 0 20px 20px 20px;
			display: flex;
			flex-wrap: wrap;
		}
		&__input {
			border-radius: 3px;
			border: 1px solid #b1b5c2;
			background-color: white;
			height: 34px;
			padding: 0 15px;
			margin-right: 35px;
			margin-top: 5px;
			margin-bottom: 5px;
		}
		button {
			height: 34px;
			border-radius: 30px;
			padding: 5px 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			font-weight: 700;
			transition: opacity .3s;
			margin: 5px;
			cursor: pointer;
			&:hover {
				opacity: 0.7;
			}
		}
		&__submit {
			border: none;
			background-color: #ff0009;
			color: white;
		}
		&__cancel {
			border: 1px solid #e8e8e9;
			background-color: white;
			color: var(--black);
		}
	}
}
