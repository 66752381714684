.modalCartwithoutRegistration {
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: center;
	background-color: #f5f2f1;
	padding-top: 25px;
	padding-bottom: 25px;
	&__wrapper {
		width: 100%;
		max-width: 850px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__info,&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__info {
		@media (width <= 639px) {
			align-items: flex-end;
		}
	}
	&__heading {
		display: flex;
		align-items: center;
		color: var(--grey);
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 15px;
		@media (width <= 639px) {
			text-align: right;
		}
		@media (width <= 479px) {
			font-size: 16px;
		}
		img {
			margin-right: 10px;
		}
	}
	&__subheading {
		color: var(--grey);
		font-size: 14px;
		font-weight: 400;
		line-height: 1;
		margin-bottom: 15px;
		@media (width <= 639px) {
			text-align: right;
		}
	}
	&__form {
		@media (width <= 639px) {
			align-items: flex-end;
		}
	}
	&__form form {
		display: grid;
		grid-template-columns: auto auto;
		grid-gap: 20px;
		margin-bottom: 15px;
		@media (width <= 767px) {
			grid-template-columns: 1fr 1fr;
			width: 100%;
		}
		@media (width <= 639px) {
			grid-template-columns: 1fr;
			justify-items: end;
		}
		@media (width <= 479px) {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
	}
	&__tel {
		width: 350px;
		max-width: 100%;
		height: 44px;
		border-radius: 3px;
		border: 1px solid #e8e8e9;
		background-color: white;
		appearance: none;
		padding-left: 15px;
		padding-right: 15px;
		color: var(--black);
		font-size: 16px;
		font-weight: 400;
		@media (width <= 767px) {
			width: 100%;
		}
		@media (width <= 639px) {
			width: 320px;
		}
	}
	&__btn {
		width: 280px;
		max-width: 100%;
		height: 44px;
		border-radius: 3px;
		border: 1px solid var(--grey);
		background-color: var(--grey);
		appearance: none;
		padding-left: 15px;
		padding-right: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		color: white;
		font-size: 18px;
		font-weight: 300;
		line-height: 1;
		@media (width <= 767px) {
			width: 100%;
		}
		@media (width <= 639px) {
			width: 320px;
			order: 2;
		}
		img {
			margin-right: 15px;
		}
	}
	&__helpText {
		color: var(--grey);
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;
		margin-bottom: 15px;
	}
}