.modalWaitingList {
	h2 {
		color: var(--black);
		font-size: 21px;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 20px;
	}
	p {
		color: #00a705;
		font-size: 16px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		a {
			color: #00a705;
			font-weight: 700;
			display: flex;
			margin-left: 5px;
			text-decoration: none;
			border-bottom: 1px dotted currentColor;
			transition: border-bottom-color .3s;
			&:hover {
				border-bottom-color: transparent;
			}
		}
	}
	.icon {
		margin-top: 20px;
		display: flex;
		width: 100%;
		justify-content: center;
		opacity: 0;
		animation: iconFadeIn .3s .15s forwards;
	}
}

#modalWaitingList {
	.modal__close:hover {
		background-color: #00a705;
	}
}

@keyframes iconFadeIn {
	from {opacity: 0;}
	to {opacity: 1;}
}