.headeCity {
	cursor: pointer;
	display: none;
	@media (width >= 768px) {
		display: flex;
		align-items: center;
		min-width: 190px;
	}
	&__current {
		font-size: 15px;
		font-weight: bold;
		color: var(--black);
	}
	&__trigger {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20px;
		cursor: pointer;
		position: relative;
		margin-left: 10px;
		path {transition: fill .3s;}
		&:hover path {fill: var(--black);}
	}
}