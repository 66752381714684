.advantages {
	display: none;
	padding-top: 25px;
	@media (width >= 1200px) {
		display: block;
	}
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 20px;
	}
	&__item--1 {--advantageBG: #ED1C24;}
	&__item--2 {--advantageBG: #00A705;}
	&__item--3 {--advantageBG: #E8B734;}
	&__item {
		border-radius: 3px;
		background-color: var(--advantageBG);
		padding: 30px;
		display: flex;
		align-items: flex-start;
	}
	&__itemImg {
		width: 102px;
		height: 102px;
		flex-shrink: 0;
		display: flex;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	&__itemText {
		margin-left: 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: white;
	}
	&__itemHeading {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
		text-transform: uppercase;
	}
	&__itemSubeading {
		font-size: 16px;
	}
}