.rateStars { 
	border: none;
	float: left;
	input { display: none; }
	label::before { 
		margin: 5px;
		width: 20px;
		height: 20px;
		content: "";
		display: block;
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M10.92.63l2.26 4.78A1.08 1.08 0 0 0 14 6l5.07.78A1.13 1.13 0 0 1 20 8.07a1.2 1.2 0 0 1-.31.64L16 12.44a1 1 0 0 0-.3 1l.86 5.24a1.13 1.13 0 0 1-.88 1.3 1.09 1.09 0 0 1-.69-.1l-4.5-2.5a1.1 1.1 0 0 0-1 0L5 19.86a1 1 0 0 1-1.45-.45 1.27 1.27 0 0 1-.12-.72l.86-5.26a1.19 1.19 0 0 0-.3-1L.31 8.71a1.17 1.17 0 0 1 0-1.58 1.1 1.1 0 0 1 .61-.34l5-.78a1.08 1.08 0 0 0 .8-.62L9 .63a1 1 0 0 1 1.43-.51.92.92 0 0 1 .49.51z" fill="#ed8a19"/></svg>');
	}
	label { 
		transition: filter .3s;
		cursor: pointer;
		filter: grayscale(1);
		float: right; 
	}
	& > input:checked ~ label,
	&:not(:checked) > label:hover,
	&:not(:checked) > label:hover ~ label {filter: grayscale(0);}

	& > input:checked + label:hover,
	& > input:checked ~ label:hover,
	& > label:hover ~ input:checked ~ label,
	& > input:checked ~ label:hover ~ label {filter: grayscale(.5);} 
}