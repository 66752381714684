.checkoutHeaderTitle {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-left: 30px;
	margin-left: 30px;
	border-left: 1px solid #b1b5c2;
	@media (width <= 479px) {
		padding-left: 15px;
		margin-left: 15px;
	}
	&__heading {
		color: #b1b5c2;
		font-size: 21px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 10px;
		@media (width <= 479px) {
			font-size: 17px;
			margin-bottom: 5px;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		text-decoration: none;
		span {
			color: var(--blue);
			font-size: 14px;
			font-weight: 400;
			margin-left: 10px;
			border-bottom: 1px solid transparent;
			transition: border-bottom-color .3s;
			&:hover {
				border-bottom-color: currentColor;
			}
		}
	}
}