.accountMain--waiting-list {
	padding-top: 25px;
	background-color: white;
	@media (width >= 1200px) {
		padding-top: 30px;
	}
	h2 {
		padding-left: 20px;
		padding-right: 20px;
		color: var(--black);
		font-size: 16px;
		font-weight: 700;
		margin-top: 0;
		margin-bottom: 25px;
		@media (width >= 768px) {
			font-size: 24px;
		}
		@media (width >= 992px) {
			text-align: left;
		}
		@media (width >= 1200px) {
			padding-left: 40px;
			padding-right: 40px;
			margin-bottom: 30px;
		}
	}
}