.catalogFilterButton {
	grid-area: flBtn;
	width: 100%;
	background-color: white;
	padding: 20px 10px;
	display: flex;
	justify-content: center;
	@media (width >= 768px) {
		justify-content: flex-start;
	}
	@media (width >= 1200px) {
		display: none;
	}
	&__btn {
		appearance: none;
		outline: none;
		width: 270px;
		height: 40px;
		border-radius: 30px;
		color: #ff1b32;
		font-size: 14px;
		font-weight: 700;
		border: 1px solid #ff1b32;
		background-color: white;
		margin: 0;
		transition: background-color .3s, color .3s;
		cursor: pointer;
		&:hover {
			background-color: #ff1b32;
			color: white;
		}
	}
}