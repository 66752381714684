.catalogCardViewTogglers {
	display: flex;
	align-items: center;
	justify-content: center;
	@media (width >= 768px) {
		display: grid;
		grid-area: flTogglers;
		grid-template-columns: 27px 30px;
		grid-column-gap: 25px;
		justify-content: end;
		padding-right: 10px;
	}
	@media (width >= 1200px) {
		padding-right: 0;
	}
	&__view {
		cursor: pointer;
		margin: 5px;
		@media (width >= 768px) {
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}
	.viewColor {
		transition: fill .3s;
		fill: #b1b5c2;
	}
	.js-triggered .viewColor {
		fill: #797979;
	}
}