.headerCatalogue {
	grid-area: hCatalogue;
	border-radius: 3px;
	background-color: var(--red);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 46px;
	color: white;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	border: none;
	outline: none;
	appearance: none;
	cursor: pointer;
	@media (width >= 768px) {
		border-radius: 0;
		height: 60px;
	}
	@media (width >= 1200px) {
		justify-content: flex-start;
		padding-left: 15px;
		padding-right: 15px;
	}
	&:active {box-shadow: inset 0 0 1px 2px #E8E8E9;}
	&__btn.burger {
		display: none;
		@media (width >= 768px) {
			display: flex;
			height: 58px;
			--burgerBG: white;
		}
		@media (width >= 1200px) {
			width: auto;
			.page--index:not(.header-is-fixed) & span {
				transform: none;
			}
		}
	}
	&__holder {
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			margin-left: 10px;
			transition: transform .3s;
			.js-triggered & {
				transform: rotateX(180deg);
			}
		}
		path {fill: white;}
		@media (width >= 768px) {
			display: none;
		}
		@media (width >= 1200px) {
			display: flex;
			margin-left: 15px;
			svg {display: none;}
		}
	}
}