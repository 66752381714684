.catalogTitle {
	grid-area: flTitle;
	display: flex;
	width: 100%;
	padding: 20px 10px 0;
	background-color: white;
	@media (width >= 768px) {
		padding: 30px 20px;
		background-color: #F3F3F3;
	}
	@media (width >= 1200px) {
		padding-left: 0;
		padding-right: 0;
	}
	&__heading {
		margin-top: 0;
		margin-bottom: 0;
		color: var(--black);
		font-size: 16px;
		font-weight: bold;
		@media (width >= 768px) {
			font-size: 21px;
		}
	}
}