html {
	box-sizing: border-box;
}
*, *::before, *::after {
	box-sizing: inherit;
}

body {
	--headerFixedIndent: 0;
	--dropdownZ: 10;
	--dropdownTextZ: 20;
	min-width: 320px;
	margin: 0;
	font-family: var(--system);
	font-weight: normal;
	background-color: var(--bodyBG);
	
	/* colors */
	--black: #333333;
	--grey: #797979;
	--blue: #2B6BDD;
	--red: #FF0009;
	--green: #00A705;
	--beige: #F7F4E7;
	--bodyBG: #F3F3F3;

	/* images */

	/* fonts */
	--system: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif;
	
	 &.menu-is-open,
	 &.filter-is-open {
		position: relative;
		&::after {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			content: "";
			display: block;
			background-color: rgba(#000, .5);
			z-index: 25;
		}
	}

	&.filter-is-open {
		position: fixed;
		top: 0;
		left: 0;
	}
	&.js-overlay--subBox::after {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		content: "";
		background: rgba(#fff, 0.8);
		z-index: 1;
		pointer-events: none;
	}

	--containerWidth: 100%;
	@media (width >= 1200px) {--containerWidth: 1140px;}
	@media (width >= 1440px) {--containerWidth: 1200px;}
	@media (width >= 1800px) {--containerWidth: 1400px;}


}

a {
	outline: none;
	color: var(--blue);
}

p {
	margin-top: 0;
	margin-bottom: 0;
}

a:hover {
	text-decoration: none;
	color: var(--linkHover);
}

a:active {outline: none;}

input, textarea, select, button {
	font-family: var(--system);
}

ul {
	margin-top: 0;
	margin-bottom: 0;
	list-style: none;
	padding-left: 0;
}

/* .container {--containerWidth: 100%;}

@media (width >= 1200px) {
	.container {--containerWidth: 1140px;}	
}

@media (width >= 1440px) {
	.container {--containerWidth: 1200px;}
	.container--wide {--containerWidth: 1320px;}
}

@media (width >= 1800px) {
	.container,.container--wide {--containerWidth: 1400px;}
} */

.container {
	width: 100%;
	max-width: var(--containerWidth); 
	margin-left: auto;
	margin-right: auto;
}

.hidden, .sr-only {
	position: absolute;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
}