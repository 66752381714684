.headerBonus {
	display: none;
	@media (width >= 1200px) {
		display: flex;
		align-items: center;
		margin-left: auto;
		text-decoration: none;
		color: #B1B5C2;
		transition: color .3s;
		&:hover {color: var(--black;)}
		path.path {
			transition: fill .3s;
			fill: currentColor;
		}
	}
	@media (width >= 1440px) {
		margin-left: 50px;
	}
	&__icon {
		margin-right: 10px;
	}
	&__title {
		font-size: 14px;
		padding-bottom: 1px;
		margin-top: 0;
		margin-bottom: 0;
	}
}