.blogTeaserCard {
	display: flex;
	flex-direction: column;
	width: 270px;
	text-decoration: none;
	color: var(--dark);
	transition: opacity .3s;
	&:hover {
		opacity: 0.7;
	}
	&__img {
		width: 100%;
		height: auto;
		display: flex;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	&__text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-top: 15px;
		padding-bottom: 15px;		
	}
	&__date {
		font-size: 14px;
		color: #999;
		margin-bottom: 10px;
	}
	&__title {
		font-size: 16px;
		color: var(--dark);
	}
}