.mMenuCatClose {
	display: none;
	position: absolute;
	top: 0;
	right:0;
	width: 50px;
	height: 58px;
	/* display: block; */
	span {
		background-color: var(--red)!;
	}
}