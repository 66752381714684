.blogTeaser {
	display: flex;
	flex-direction: column;
	align-items: center;
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		padding-top: 30px;
		padding-bottom: 30px;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
		@media (width >= 1200px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&__slidesWrapper {
	}
	&__btn {
		margin-top: 30px;
	}
}