.categoryList {
	padding-top: 40px;
	padding-bottom: 40px;
	display: flex;
	justify-content: center;
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		grid-gap: 40px 20px;
		@media (width <= 1199px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

.categoryListItem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media (width <= 639px) {
		align-items: center;
	}
	&:nth-child(1) {--catDirectionColor: #ff9096;}
	&:nth-child(2) {--catDirectionColor: #ae00b1;}
	&:nth-child(3) {--catDirectionColor: #74cd46;}
	&:nth-child(4) {--catDirectionColor: #f7b700;}
	&:nth-child(5) {--catDirectionColor: #00b6fe;}
	&:nth-child(6) {--catDirectionColor: #00c995;}
	&:nth-child(7) {--catDirectionColor: #fc0059;}
	&:nth-child(8) {--catDirectionColor: #0077cb;}
	&__heading {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	&__icon {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		margin-right: 20px;
	}
	&__name {
		color: var(--catDirectionColor);
		font-size: 18px;
		font-weight: 700;
	}
	&__list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@media (width <= 639px) {
			align-items: center;
		}
		li {
			display: flex;
		}
		a {
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			line-height: 2;
			text-decoration: none;
			transition: color .3s;
			&:hover {
				color: var(--red);
			}
		}
	}
}