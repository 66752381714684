.siteFooter {
	&__wrapper {
		@media (width >= 768px) {
			padding: 50px 20px 5px;
			display: grid;
			grid-template-columns: 220px 1fr 220px;
			grid-gap: 0 25px;
			grid-template-areas: 
			"fLogo fMenu fTel"
			"fCopy fMenu fTel"
			"fSoc fMenu fPay";
			align-items: start;
		}
	}
}
