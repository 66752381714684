.itemMain {
	width: 100%;
	display: flex;
	&__wrapper {
		width: 100%;
		background-color: white;
		display: grid;
		align-items: center;
		grid-template-columns: 1fr auto;
		grid-template-areas:
		"iId iId"
		"iLabels iLabels"
		"iSlider iSlider"
		"iQuestions iQuestions"
		"iSales iSales"
		"iPrice iBonuses"
		"iOptions iOptions"
		"iBuy iBuy"
		"iEv iButtons"
		"iDelivery iDelivery";
		padding: 0 10px;
		@media (width >= 768px) {
			padding-left: 20px;
			padding-right: 20px;
			grid-template-columns: 1fr auto 1fr;
			grid-template-areas:
			"iEv iEv iId"
			"iSlider iSlider iSlider"
			". iQuestions ."
			"iPrice iBonuses iSales"
			"iOptions iOptions iOptions"
			"iBuy iButtons ."
			"iDelivery iDelivery iDelivery";
		}
		@media (width >= 1200px) {
			padding: 30px;
			grid-template-columns: 2fr auto 1fr;
			grid-template-rows: 50px 80px 120px auto auto auto;
			grid-column-gap: 40px;
			align-items: start;
			grid-template-areas:
			"iSlider iEv iId"
			"iSlider iPrice iBonuses"
			"iSlider iSales ."
			"iSlider iOptions iOptions"
			"iQuestions iBuy iButtons"
			"iQuestions iDelivery iDelivery";
		}
	}
	&__id {
		grid-area: iId;
	}
	&__labels {
		grid-area: iLabels;
		align-self: start;
		@media (width >= 768px) {
			grid-column: 3/4;
			grid-row: 2/3;
		}
		@media (width >= 1200px) {
			grid-column: 1/2;
			grid-row: 1/2;
		}
	}
	&__slider {
		grid-area: iSlider;
		width: 100%;
		height: 100%;
	}
	&__questions {
		grid-area: iQuestions;
		@media (width >= 1200px) {
			align-self: start;
		}
	}
	&__sales {
		grid-area: iSales;
	}
	&__price {
		grid-area: iPrice;
	}
	&__bonuses {
		grid-area: iBonuses;
		align-self: center;
	}
	&__options {
		grid-area: iOptions;
		max-width: 100vw;
		overflow: hidden;
	}
	&__buy {
		grid-area: iBuy;
	}
	&__evaluation {
		grid-area: iEv;
		@media (width >= 768px) {
			display: flex;
			align-items: center;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	&__buttons {
		grid-area: iButtons;
		display: flex;
		place-self: start end;
		padding-top: 5px;
		width: 100%;
		@media (width >= 768px) {
			place-self: start center;
			padding-top: 25px;
		}
		@media (width >= 992px) {
			place-self: start start;
		}
	}

	&__delivery {
		grid-area: iDelivery;
	}

	&__wrapper.not-available {
		@media (width >= 768px) {
			grid-template-areas:
			"iEv iEv iId"
			"iSlider iSlider iSlider"
			". iQuestions ."
			"iBuy . iButtons"
			"iDelivery iDelivery iDelivery";
		}
		@media (width >= 1200px) {
			grid-template-rows: 50px auto auto;
			align-items: start;
			grid-template-areas:
			"iSlider iEv iId"
			"iSlider iBuy iButtons"
			"iQuestions iDelivery iDelivery";
		}
	}

	&__wrapper.not-available &__buy {
		@media (width >= 768px) {
			min-width: 240px;
		}
	}

	&__wrapper.not-available &__buttons {
		min-width: 160px;
		@media (width >= 768px) {
			padding-top: 5px;
		}
	}

	&__wrapper.not-available &__sales,
	&__wrapper.not-available &__price,
	&__wrapper.not-available &__bonuses,
	&__wrapper.not-available &__options {
		display: none;
	}
}
