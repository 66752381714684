.js-dropdownContent {
	position: absolute;
	display: none;
	opacity: 0;
	transform: translateY(10px);
	transition: opacity .3s, transform .3s;
	pointer-events: none;
	.js-dropdownContainer.js-active & {
		z-index: var(--dropdownZ);
		opacity: 1;
		transform: translateY(0);
		pointer-events: all;
	}
	.js-transitioning & {display: flex;}
}

.js-dropdownFirstTrigger {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	cursor: pointer;
	position: relative;
	margin-left: 10px;
	svg {transition: transform .3s;}
	path {transition: fill .3s;}
	.js-dropdownContainer.js-transitioning & {	z-index: var(--dropdownTextZ);}
	&:hover path,.js-dropdownContainer.js-active & path {fill: var(--black);}
	.js-dropdownContainer.js-active &  svg {transform: rotateX(180deg);}
	.js-dropdownContainer:not(.js-active) svg {transform: rotateX(0deg);}
	.js-dropdownHoverTrigger & {pointer-events: none;}
}

.js-dropdownHoverTrigger .js-dropdownSecondTrigger {
	pointer-events: none;
}