.cartItem {
	display: flex;
	width: 100%;
	padding-bottom: 10px;
	margin-bottom: 10px;
	position: relative;
	&:last-of-type {
		margin-bottom: 20px;
	}
	&::after {
		position: absolute;
		content: "";
		display: block;
		width: calc(100% + 40px);
		height: 1px;
		top: 100%;
		left: -20px;
		background-color: #e8e8e9;
	}
	&__link {
		flex-basis: calc(100% - 30px);
		display: flex;
		align-items: center;
		text-decoration: none;
		transition: filter .3s;
		&:hover {
			filter: opacity(.7);
		}
	}
	&__img {
		width: 50px;
		height: 50px;
		object-fit: cover;
		object-position: center;
	}
	&__title {
		padding-left: 20px;
		padding-right: 20px;
		color: #2b6bdd;
		font-size: 14px;
		font-weight: 400;
		line-height: normal;
	}
	&__itemCount {
		color: var(--grey);
	}
	&__itemDelete {
		flex-basis: 30px;
		border: none;
		outline: none;
		appearance: none;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><path fill="#FF0009" d="M5.9 5l4-4a.22.22 0 0 0 0-.31L9.36.07a.22.22 0 0 0-.31 0H9l-4 4-4-4a.22.22 0 0 0-.32 0L.07.64A.22.22 0 0 0 .06 1l4 4-4 4a.22.22 0 0 0 0 .31l.57.57a.22.22 0 0 0 .31 0H1l4-4 4 4a.22.22 0 0 0 .31 0l.57-.57a.22.22 0 0 0 0-.31z"/></svg>');
		filter: grayscale(1);
		transition: filter .3s;
		cursor: pointer;
		&:hover {
			filter: grayscale(0);
		}
	}
}