.brandsMain {
	&__heading {
		color: var(--black);
		font-size: 21px;
		font-weight: 700;
		margin-top: 30px;
		margin-bottom: 30px;
		display: flex;
		@media (width <= 1199px) {
			padding-left: 20px;
			padding-right: 20px;
		}
		@media (width <= 767px) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__item {
		width: 100%;
		display: flex;
		align-items: flex-start;
		background-color: white;
		margin-bottom: 1px;
		padding: 25px 30px;
	}
	&__letter {
		color: var(--red);
		font-size: 36px;
		font-weight: 400;
		text-transform: uppercase;
		margin-right: 50px;
	}
	&__items {
		flex-basis: calc(100% - 100px);
		flex-grow: 1;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
		li {
			margin-bottom: 10px;
		}
		a {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
			transition: color .3s;
			text-decoration: none;
			&:hover {
				color: var(--red);
			}
		}
	}
}