.recentViews {
	&__wrapper {
		display: grid;
		grid-gap: 2px;
		grid-template-columns: repeat(2, calc(50% - 1px));
		@media (width >= 768px) {grid-template-columns: repeat(3, calc(33.33% - 1px));}
		@media (width >= 1200px) {grid-template-columns: repeat(4, calc(25% - 1px));}
	}
	.itemCard {
		&:nth-child(n+3) {display: none;}
		@media (width >= 768px) {
			&:nth-child(n+3) {display: flex;}
			&:nth-child(n+4) {display: none;}
		}
		@media (width >= 1200px) {
			&:nth-child(n+4) {display: flex;}
			&:nth-child(n+5) {display: none;}
		}
	}
}