.itemMainDelivery {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	padding-top: 10px;
	@media (width >= 768px) {
		padding-top: 20px;
	}
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: -10px;
		background-color: #E8E8E9;
		width: calc(100% + 20px);
		height: 1px;
		@media (width >= 768px) {
			width: calc(100% + 20px);
			left: -20px;
		}
		@media (width >= 1200px) {
			width: 100%;
			left: 0;
		}
	}
	.headeCity {
		margin-bottom: 25px;
		display: flex;
		align-items: center;
		&__text {
			color: var(--black);
			font-size: 16px;
			font-weight: 700;
			margin-right: 10px;
		}
		&__current {
			color: #2b6bdd;
		}
		path {
			fill: #2b6bdd;
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		@media (width >= 768px) {
			flex-direction: row;
		}
	}
	&__item {
		width: 100%;
		display: flex;
		align-items: flex-start;
		margin-bottom: 30px;
		@media (width >= 768px) {
			flex-basis: 33%;
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			margin-bottom: 0;
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
			&:not(:last-child) {
				position: relative;
				&::after {
					display: block;
					content: "";
					position: absolute;
					width: 2px;
					height: 100%;
					top: 0;
					right: 0;
					background-image: linear-gradient(180deg, white 0%, #e8e8e9 47%, white 100%);
				}
			}
		}
		@media (width >= 1200px) {
			padding-left: 5px !important;
			padding-right: 5px !important;
		}
	}
	&__itemIcon {
		width: 45px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	&__itemText {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: calc(100% - 25px);
		padding-left: 5px;
		b {
			color: var(--black);
			font-size: 16px;
			font-weight: 700;
			line-height: 22px;
			margin-bottom: 5px;
		}
		p {
			color: var(--black);
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}
	}
}