.catalogFilters {
	.priceInput {
		width: 60px;
		height: 30px;
		border-radius: 3px;
		border: 1px solid #e8e8e9;
		background-color: white;
		padding: 5px;
		color: var(--black);
		font-size: 14px;
		font-weight: 700;
		appearance: none;
		box-shadow: none;
	}
	.noUi-horizontal {
		height: 8px;
	}

	.noUi-target {
		background-color: white;
	}

	.noUi-connect {
		background-color: var(--red);
	}
	
	.noUi-horizontal .noUi-handle {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: #535353;
		box-shadow: 0 0 1px #535353;
		border: 10px solid white;
		top: -12px;
		cursor: pointer;
		outline: none;
		transition: background-color .3s;
		&:active {
			background-color: var(--red);
		}
		&::before,&::after {display: none;}
	}
}