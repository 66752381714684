.itemMainOptions {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 5px;
	padding-bottom: 15px;
	@media (width >= 768px) {
		position: relative;
		padding-top: 15px;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: -20px;
			background-color: #E8E8E9;
			width: calc(100% + 40px);
			height: 1px;
		}
	}
	@media (width >= 1200px) {
		&::before {
			width: 100%;
			left: 0;
		}
	}
	&__current {
		color: var(--black);
		font-size: 15px;
		font-weight: 700;
		line-height: 22px;
		margin-bottom: 10px;
	}
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		width: 100%;
		grid-gap: 15px 10px;
		@media (width >= 768px) {
			grid-template-columns: repeat(5, 1fr);
		}
		@media (width >= 992px) {
			grid-template-columns: repeat(6, 1fr);
		}
		@media (width >= 1200px) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	&__item {
		display: none;
		flex-direction: column;
		align-items: center;
		flex-basis: calc(50% - 5px);
		margin-bottom: 15px;
		cursor: pointer;
		&:nth-child(-n + 4) {
			display: flex;
		}
		@media (width >= 768px) {
			&:nth-child(-n + 5) {
				display: flex;
			}
		}
		@media (width >= 992px) {
			&:nth-child(-n + 6) {
				display: flex;
			}
		}
		@media (width >= 1200px) {
			&:nth-child(-n + 6) {
				display: none;
			}
			&:nth-child(-n + 4) {
				display: flex;
			}
		}
	}
	&__itemImg {
		border-radius: 3px;
		border: 2px solid #b1b5c2;
		transition: border-color .3s;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		&:hover,&.active {
			border-color: #ED8A19;
		}
	}
	&__itemTitle {}
	&__itemPrice {
		color: var(--black);
		font-size: 15px;
		font-weight: 400;
		line-height: 22px;
	}
	&__trigger {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		span {
			color: var(--grey);
			font-size: 12px;
			font-weight: 400;
			line-height: 20px;
			margin-right: 5px;
		}
		&.active &__trigger svg {
			transform: rotateX(180deg);
		}
	}
	&.active &__item {
		display: flex !important;
	}
}