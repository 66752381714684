.itemMainRate {
	display: flex;
	align-items: center;
	padding-top: 15px;
	padding-bottom: 5px;
	@media (width >= 768px) {
		padding-top: 0;
		padding-bottom: 0;
	}
	i {
		display: flex;
		width: 17px;
		height: 20px;
		background-image: url(../img/fave.svg);
		background-repeat: no-repeat;
		background-size: 100%;
		&.disabled {
			filter: grayscale(1);
		}
	}
	b {
		color: var(--black);
		font-size: 15px;
		font-weight: 700;
		margin-left: 5px;
		line-height: 20px;
	}
}