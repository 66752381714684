.testimonials {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	outline: none!important;
	&__wrapper {
		width: 100%;
		background-color: #FFF;
		padding-bottom: 40px;
	}
	&__heading {
		padding: 25px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width >= 768px) {
			flex-direction: row;
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}
	&__stat {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
	}
	&__rate {
		display: flex;
		align-items: center;
		i {
			display: flex;
			width: 17px;
			height: 20px;
			background-image: url(../img/fave.svg);
			background-repeat: no-repeat;
			background-size: 100%;
		}
		b {
			color: var(--black);
			font-size: 15px;
			font-weight: 700;
			margin-left: 5px;
			line-height: 20px;
		}
	}
	&__qty {
		color: #2b6bdd;
		font-size: 13px;
		font-weight: 400;
		line-height: 20px;
		padding-left: 10px;
		margin-left: 10px;
		position: relative;
		&::before {
			display: block;
			position: absolute;
			content: "";
			width: 1px;
			height: 16px;
			background-color: #999999;
			top: 1px;
			left: 0;
		}
	}
	&__postTestimonial {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 165px;
		color: #ff1b32;
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
		height: 34px;
		border-radius: 30px;
		border: 1px solid #ff1b32;
		padding: 5px 25px;
		text-decoration: none;
		transition: background-color .3s, color .3s;
		&--cancel {display: none;}
		&:hover {
			color: white;
			background-color: #ff1b32;
		}
		@media (width >= 768px) {
			margin-left: auto;
		}
	}
	.post-active &__postTestimonial {
		display: none;
		&--cancel {display: flex;}
	}
}