.itemMainBuy {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 5px;
	padding-bottom: 15px;
	position: relative;
	&::after {
		content: "";
		position: absolute;
		display: block;
		width: calc(100% + 20px);
		height: 1px;
		left: -10px;
		bottom: 0;
		background-color: #E8E8E9;
		@media (width >= 768px) {
			display: none;
		}
	}
	&__button {
		width: 100%;
		max-width: 320px;
		height: 40px;
		background-color: #009004;
		border: 1px solid #009004;
		color: white;
		font-size: 18px;
		font-weight: 700;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 10px;
		border-radius: 3px;
		text-decoration: none;
		transition: background-color .3s, color .3s;
		@media (width >= 768px) {
			height: 55px;
			font-size: 21px;
		}
		&:hover {
			color: #009004;
			background-color: white;
		}
	}
	&__button--not {
		background-color: var(--red);
		border-color: var(--red);
		font-size: 17px!important;
		&:hover {
			color: var(--red);
		}
	}
	&__presence {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		&.green {
			color: #00a705;
		}
		&.yellow {
			color: #ed8a19;
		}
	}
}