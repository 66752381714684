.itemMainTestimonials {
	color: #2b6bdd;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	padding-top: 5px;
	padding-bottom: 15px;
	a {
		text-decoration: none;
	}
	@media (width >= 768px) {
		padding-top: 0;
		padding-bottom: 0;
		margin-left: 15px;
		padding-left: 15px;
		position: relative;
		&::before {
			position: absolute;
			display: block;
			content: "";
			width: 1px;
			height: 16px;
			background-color: #b1b5c2;
			top: 2px;
			left: 0;
		}
	}
}