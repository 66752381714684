.modalCartButtons {
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
	&__wrapper {
		width: 100%;
		max-width: 850px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 60px;
		@media (width <= 991px) {
			grid-gap: 20px;
		}
		@media (width <= 767px) {
			grid-template-columns: 1fr;
		}
	}
	&__left,&__right {
		display: flex;
		flex-direction: column;
		align-items: center;
		@media (width <= 767px) {
			align-items: flex-end;
		}
	}
	&__btn {
		height: 65px;
		width: 100%;
		border: 1px solid;
		outline: none;
		appearance: none;
		background-image: none;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
		transition: color .3s, background-color .3s;
		font-size: 21px;
		font-weight: 700;
		line-height: 24px;
		cursor: pointer;
		text-decoration: none;
		@media (width <= 767px) {
			max-width: 320px;
		}
		@media (width <= 479px) {
			font-size: 16px;
		}
		&--keep {
			border-color: #ededee;
			color: var(--black);
			background-color: white;
			& * {
				pointer-events: none;
			}
			&:hover {
				background-color: #ededee;
			}
			img {margin-right: 20px;}
		}
		&--order {
			border-color: #ff1b32;
			color: white;
			background-color: #ff1b32;
			&:hover {
				background-color: white;
				color: #ff1b32;
			}
		}
	}
	
	&__helpText {
		color: var(--grey);
		font-size: 12px;
		font-weight: 400;
		line-height: 1;
		margin-top: 10px;
	}

}