.headerBrands {
	display: none;
	grid-area: hBrands;
	@media (width >= 768px) {
		display: block;
		padding-left: 25px;
		padding-right: 25px;
		background-color: #535353;
	}
	&__list {
		display: flex;
		width: 100%;
		height: 60px;
		justify-content: center;
		align-items: center;
		@media (width >= 1200px) {
			justify-content: space-around;
			.header-is-fixed & {justify-content: center;}
		}

	}
	&__item {
		@media (width >= 1200px) {
			position: relative;
			&:last-child {margin-left: 20px;}
			.header-is-fixed &:last-child {margin-left: 0;}
		}
	}
	&__item:last-child a {text-transform: none;}
	&__linkPage {
		display: none;
		@media (width >= 768px) {
			display: none;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			font-weight: bold;
			color: white;
			text-transform: uppercase;
			text-decoration: none;
			&:hover {color: white;}
			&.is-visible {
				display: flex;
				text-align: center;
			}
		}
		@media (width >= 1200px) {
			display: flex;
			padding: 5px 7px;
			border-radius: 3px;
			transition: color .3s, background-color .3s;
			&:hover {
				color: #535353;
				background-color: white;
			}
		}
	}
}


.header-is-fixed [data-fixedOutput] {
	position: relative;
	font-size: 0;
	&::after {
		width: 100%;
		font-size: 14px;
		content: attr(data-fixedOutput);
	}
}

@media (width <= 1199px) {
	[data-fixedOutput] {
		position: relative;
		font-size: 0;
		&::after {
			width: 100%;
			font-size: 14px;
			content: attr(data-fixedOutput);
		}
	}
}