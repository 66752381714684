.checkoutHeader {
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: white;
	@media (width <= 992px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	@media (width <= 479px) {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	&__wrapper {
		display: flex;
		width: 100%;
		@media (width <= 1199px) {
			padding-right: 15px;
		}
	}
	.headerLogo {
		justify-content: flex-start;
		@media (width >= 1200px) {
			flex-basis: 270px;
		}
		@media (width <= 767px) {
			margin-left: 20px;
		}
		@media (width <= 479px) {
			margin-left: 15px;
		}
	}
	.headerTels {
		margin-left: auto;
	}
}