.replyTestimonial {
	display: none;
	.reply-active & {
		width: 100%;
		max-width: 955px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 30px 10px;
		background-color: #f3f3f3;
		position: relative;
		@media (width >= 768px) {
			padding: 40px 60px;
		}
		@media (width >= 992px) {
			left: 20px;
		}
	}
	&__cancel {
		margin-left: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 165px;
		color: #ff1b32;
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
		height: 34px;
		border-radius: 30px;
		border: 1px solid #ff1b32;
		padding: 5px 25px;
		text-decoration: none;
		transition: background-color .3s, color .3s;
		&:hover {
			color: white;
			background-color: #ff1b32;
		}
		@media (width >= 992px) {
			position: absolute;
			top: 15px;
			right: 60px;
		}
	}
	&__form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
	}
	&__item {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 15px;
		span {
			color: var(--black);
			font-size: 14px;
			font-weight: 700;
			margin-bottom: 10px;
		}
		input[type="text"],
		textarea {
			border-radius: 3px;
			border: 1px solid #e8e8e9;
			background-color: white;
			padding: 5px 15px;
			color: var(--black);
			font-size: 16px;
			font-weight: 400;
			width: 100%;
		}
		input[type="text"] {
			height: 46px;
		}
		textarea {
			resize: none;
			min-height: 110px;
		}
	}
	&__submit {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 320px;
		height: 60px;
		border-radius: 3px;
		border: 1px solid #ff0009;
		background-color: #ff0009;
		color: white;
		font-family: Helvetica;
		font-size: 21px;
		font-weight: 700;
		line-height: 24px;
		transition: background-color .3s, color .3s;
		appearance: none;
		outline: none;
		box-shadow: none;
		cursor: pointer;
		margin-left: auto;
		margin-right: auto;
		&:hover {
			background-color: white;
			color: #ff0009;
		}
	}
}