.itemMainQuestions {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50px;
	text-decoration: none;
	transition: opacity .3s;
	&:hover {
		opacity: 0.7;
	}
	span {
		color: var(--black);
		font-size: 13px;
		font-weight: 400;
		margin-left: 10px;
	}
}