.searchNoResult {
	padding: 40px 15px;
	display: flex;
	justify-content: center;
	background-color: white;
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	&__heading {
		color: var(--black);
		font-size: 21px;
		font-weight: 700;
		line-height: 1.5;
		margin-top: 20px;
		margin-bottom: 10px;
		text-align: center;
	}
	&__subheading {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: var(--black);
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
	}
}