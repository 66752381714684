.checkoutInfo {
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #f3f3f3;
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@media (width <= 1199px) {
			padding-left: 20px;
			padding-right: 20px;
		}
		@media (width <= 479px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	&__heading {
		color: var(--black);
		font-size: 21px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 5px;
		b {
			color : var(--green);
		}
	}
	&__subheading {
		color: var(--black);
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
	}
}